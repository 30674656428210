import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import {
  TextField,
} from "@mui/material";
import {
  ModalOverlay,
  UploadContent,
  UploadTxt,
  ContentDiv,
  TaskTitle,
  TaskDescription,
  ScheduleDiv,
  CustomDiv,
  TaskTxt,
  TabsDiv,
  Tab,
  ModalBtn,
  AddTask,
} from "./Style";
import CloseX from "../../assets/x-close.svg";
import Schedule from "../../assets/schedule.svg";
import Sort from "../../assets/sort.svg";
import Plus from "../../assets/plus-grey.svg";
import InfoIcon from "../../assets/info.svg";
import { CustomDateTimePicker, CustomSelect } from "../../utils/utils";
import { CircularProgress } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {
  repeatOptions,
  recurrenceOptions,
  weekDayOptions,
  occurrenceOptions,
  commandOptions,
  TimeInfo
} from "../../Components/Constants/Constants";

const TaskModal = ({ closeAddTaskModal, fetchEvents }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startdatetime, setStartDatetime] = useState(dayjs().add(1, 'hour'));
  const [enddatetime, setEndDatetime] = useState(dayjs().add(2, 'hour'));
  const [repeatOption, setRepeatOption] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState('One Time');
  const [selectedDates, setSelectedDates] = useState([1]);
  const [selectedWeekDays, setSelectedWeekDays] = useState(['MO']);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState();
  const [selectedOccurrence, setSelectedOccurrence] = useState();
  const [error, setError] = useState('');
  const [selectedTab, setSelectedTab] = useState('Task');
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');

  const monthDates = Array.from({ length: 31 }, (_, i) => i + 1);

  const dateOptions = monthDates.map(date => ({
    value: date,
    label: date.toString()
  }));

  useEffect(() => {
    setEndDatetime(dayjs(startdatetime).add(1, 'hour'));
  }, [startdatetime]);

  // Reset values when recurrence type changes
  useEffect(() => {
    if (recurrenceType === 'daily') {
      // Reset weekly and monthly values
      setSelectedWeekDays(['MO']);
      setSelectedDates([1]);
      setSelectedDayOfWeek('');
      setSelectedOccurrence('');
    } else if (recurrenceType === 'weekly') {
      // Reset monthly values
      setSelectedDates([1]);
      setSelectedDayOfWeek('');
      setSelectedOccurrence('');
    } else if (recurrenceType === 'monthly') {
      // Reset weekly values
      setSelectedWeekDays(['MO']);
    }
  }, [recurrenceType]);

  // Reset all custom recurrence values when repeat option changes
  useEffect(() => {
    if (!repeatOption) {
      setRecurrenceType('One Time');
      setSelectedWeekDays(['MO']);
      setSelectedDates([1]);
      setSelectedDayOfWeek('');
      setSelectedOccurrence('');
    }
  }, [repeatOption]);

  const isMonthlyPatternDisabled = selectedDates.length > 0;
  const isMonthlyDatesDisabled = selectedDayOfWeek || selectedOccurrence;

  // Function to handle command text change
  const handleCommandTextChange = (text) => {
    setDescription(text);
  };

  // Fetch workflows when Workflow tab is selected
  useEffect(() => {
    if (selectedTab === 'Workflow') {
      const fetchWorkflows = async () => {
        try {
          const userId = localStorage.getItem('id');
          const response = await fetch(`https://api.aiflowpro.io/api/workflow/getworkflows?user_id=${userId}`);
          const data = await response.json();
          setWorkflows(data.workflows);
        } catch (error) {
          console.error('Failed to fetch workflows:', error);
        }
      };
      fetchWorkflows();
    }
  }, [selectedTab]);

  // Transform workflows for CustomSelect options
  const workflowOptions = workflows.map(workflow => ({
    value: workflow._id,
    label: workflow.name
  }));

  const handleSubmit = async () => {
    if (selectedTab === 'Task' && (!title.trim() || !description.trim())) {
      setError('Title and description are required fields');
      return;
    }

    if (selectedTab === 'Workflow' && !selectedWorkflow) {
      setError('Please select a workflow');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const userId = localStorage.getItem('id');
      
      // Base payload
      const payload = {
        userId,
        start: startdatetime.toISOString(),
        end: enddatetime.toISOString(),
        isRecurring: recurrenceType !== 'One Time'
      };

      if (selectedTab === 'Task') {
        payload.taskTitle = title;
        payload.taskDescription = description;
      } else {
        const selectedWorkflowData = workflows.find(w => w._id === selectedWorkflow);
        payload.workflow_id = selectedWorkflow;
        payload.workflow_title = selectedWorkflowData.name;
        payload.workflow_description = selectedWorkflowData.description;
        payload.timestamp = new Date().toISOString();
        payload.user_email = localStorage.getItem('email');
        payload.user_name = localStorage.getItem('name');
      }

      // Add recurrence fields based on conditions
      if (recurrenceType !== 'One Time') {
        payload.recurrenceType = recurrenceType;

        if (recurrenceType === 'weekly') {
          payload.byweekday = selectedWeekDays;
        } 
        else if (recurrenceType === 'monthly') {
          if (isMonthlyPatternDisabled) {
            payload.bydateofmonth = selectedDates;
          } else if (isMonthlyDatesDisabled) {
            payload.byweekdayofmonth = [{
              day: selectedDayOfWeek,
              occurrence: parseInt(selectedOccurrence)
            }];
          }
        }
      }

      const response = await fetch('https://api.aiflowpro.io/api/task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to create task');
      }

      await fetchEvents();
      closeAddTaskModal();
    } catch (error) {
      setError('Failed to create task. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalOverlay>
      <UploadContent style={{height: "90%", gap: "1rem"}}>
        <div style={{ display: "flex", marginLeft: "auto", gap: "0.5rem" }}>
          <img
            src={CloseX}
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={closeAddTaskModal}
          />
        </div>
        <TabsDiv>
          <Tab 
            onClick={() => setSelectedTab('Task')}
            style={{ 
              backgroundColor: selectedTab === 'Task' ? '#0062ff' : '#F1F1F5',
              color: selectedTab === 'Task' ? 'white' : 'inherit'
            }}
          >
            Task
          </Tab>
          <Tab 
            onClick={() => setSelectedTab('Workflow')}
            style={{ 
              backgroundColor: selectedTab === 'Workflow' ? '#0062ff' : '#F1F1F5',
              color: selectedTab === 'Workflow' ? 'white' : 'inherit'
            }}
          >
            Workflow
          </Tab>
        </TabsDiv>
        <ContentDiv>
          {selectedTab === 'Task' ? (
            <>
              <TextField
                placeholder="Enter title for your task"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "0.5rem",
                    border: "1px solid #EBEBFD",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "11px 16px",
                  },
                }}
              />
              <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                <TaskDescription
                  as="textarea"
                  placeholder="Task Details"
                  value={description}
                  onChange={(e) => handleCommandTextChange(e.target.value)}
                  style={{minHeight: "8.125rem"}}
                />
              </div>
            </>
          ) : (
            <CustomSelect
              value={selectedWorkflow}
              onChange={(e) => setSelectedWorkflow(e.target.value)}
              options={workflowOptions}
              placeholder="Select a workflow"
            />
          )}
          <ScheduleDiv style={{marginTop: "0.5rem"}}>
            <img src={Schedule} alt="schdule" />
            <CustomDateTimePicker
              label="Start Date & Time"
              value={startdatetime}
              onChange={(newValue) => setStartDatetime(newValue)}
              disablePast="disablePast"
            />
            <Tooltip title={TimeInfo}>
              <img src={InfoIcon} alt="info" />
            </Tooltip>
          </ScheduleDiv>
          <CustomDiv>
            <TaskTitle style={{fontSize: "1.125rem"}}>Recurrence</TaskTitle>
            <ScheduleDiv>
              <img src={Sort} alt="" />
              <CustomSelect
                value={recurrenceType}
                onChange={(e) => setRecurrenceType(e.target.value)}
                options={recurrenceOptions}
              />
            </ScheduleDiv>
            {recurrenceType === 'weekly' && (
              <ScheduleDiv id="weekly-schedule">
                <TaskTxt>Repeat on</TaskTxt>
                <CustomSelect
                  value={selectedWeekDays[0]}
                  onChange={(e) => setSelectedWeekDays([e.target.value])}
                  options={weekDayOptions}
                  renderValue={(selected) => weekDayOptions.find(option => option.value === selected)?.label}
                />
              </ScheduleDiv>
            )}
            {recurrenceType === 'monthly' && (
              <ScheduleDiv id="monthly-schedule">
                <TaskTxt>On Date</TaskTxt>
                <CustomSelect
                  value={selectedDates[0]}
                  onChange={(e) => setSelectedDates([e.target.value])}
                  options={dateOptions}
                  renderValue={(selected) => selected.toString()}
                  disabled={isMonthlyDatesDisabled}
                />
              </ScheduleDiv>
            )}
          </CustomDiv>
        </ContentDiv>
        {error && (
          <div style={{ color: 'red', textAlign: 'center', margin: '1rem 0' }}>
            {error}
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginTop: "auto" }}>
          <UploadTxt style={{ lineHeight: "1.5rem", fontWeight: "600", fontSize: "1rem", cursor: "pointer" }} onClick={closeAddTaskModal}>
            Skip
          </UploadTxt>
          {isLoading ? (
            <CircularProgress size={24} style={{ margin: '0 1rem' }} />
          ) : (
            <ModalBtn onClick={handleSubmit}>Done</ModalBtn>
          )}
        </div>
      </UploadContent>
    </ModalOverlay>
  );
};

export default TaskModal;