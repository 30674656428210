import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import {
  ModalOverlay,
  UploadContent,
  UploadTxt,
  ContentDiv,
  TaskTitle,
  TaskDescription,
  ScheduleDiv,
  CustomDiv,
  TaskTxt,
  ModalBtn
} from "./Style";
import CloseX from "../../assets/x-close.svg";
import Edit from "../../assets/edit.svg";
import DeleteIcon from "../../assets/delete-grey.svg";
import Schedule from "../../assets/schedule.svg";
import Sort from "../../assets/sort.svg";
import { CustomDateTimePicker, CustomSelect } from "../../utils/utils";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const TaskModal = ({ closeModal, event, fetchEvents }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  
  // Form states
  const [title, setTitle] = useState(event.title);
  const [description, setDescription] = useState(event.description);
  const [startdatetime, setStartDatetime] = useState(dayjs(event.start));
  const [enddatetime, setEndDatetime] = useState(dayjs(event.end));
  const [recurrenceType, setRecurrenceType] = useState(event.recurrenceType || 'One Time');
  const [selectedWeekDays, setSelectedWeekDays] = useState(event.byweekday || ['MO']);
  const [selectedDates, setSelectedDates] = useState(event.bydateofmonth || [1]);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(
    event.byweekdayofmonth?.[0]?.day || ''
  );
  const [selectedOccurrence, setSelectedOccurrence] = useState(
    event.byweekdayofmonth?.[0]?.occurrence?.toString() || ''
  );

  console.log("event", event);

  const recurrenceOptions = [
    { value: 'One Time', label: 'One Time' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' }
  ];

  const weekDayOptions = [
    { value: 'MO', label: 'Monday' },
    { value: 'TU', label: 'Tuesday' },
    { value: 'WE', label: 'Wednesday' },
    { value: 'TH', label: 'Thursday' },
    { value: 'FR', label: 'Friday' },
    { value: 'SA', label: 'Saturday' },
    { value: 'SU', label: 'Sunday' }
  ];

  const dateOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: (i + 1).toString()
  }));

  const isMonthlyPatternDisabled = selectedDates.length > 0;
  const isMonthlyDatesDisabled = selectedDayOfWeek || selectedOccurrence;
    
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("https://api.aiflowpro.io/api/task/deleteTask", {
        taskId: event.id,
      });
      if (response.status === 200 && response.data.responseCode === 200) {
        closeModal();
        fetchEvents();
      } else {
        setError('Failed to delete task');
      }
    } catch (error) {
      setError('Error deleting task');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!title.trim() || !description.trim()) {
      setError('Title and description are required fields');
      return;
    }

    setIsEditLoading(true);
    setError('');

    try {
      const payload = {
        taskId: event.id,
        taskTitle: title,
        taskDescription: description,
        start: startdatetime.toISOString(),
        end: enddatetime.toISOString(),
        isRecurring: recurrenceType !== 'One Time'
      };

      if (recurrenceType !== 'One Time') {
        payload.recurrenceType = recurrenceType;

        if (recurrenceType === 'weekly') {
          payload.byweekday = selectedWeekDays;
        } 
        else if (recurrenceType === 'monthly') {
          if (isMonthlyPatternDisabled) {
            payload.bydateofmonth = selectedDates;
          } else if (isMonthlyDatesDisabled) {
            payload.byweekdayofmonth = [{
              day: selectedDayOfWeek,
              occurrence: parseInt(selectedOccurrence)
            }];
          }
        }
      }

      const response = await axios.post('https://api.aiflowpro.io/api/task/editTask', payload);
      
      if (response.status === 200) {
        setIsEditing(false);
        closeModal();
        fetchEvents();
      } else {
        setError('Failed to update task');
      }
    } catch (error) {
      setError('Error updating task');
    } finally {
      setIsEditLoading(false);
    }
  };

  return (
    <ModalOverlay>
      <UploadContent style={{ height: isEditing ? "90%" : "auto" }}>
        <div style={{ display: "flex", marginLeft: "auto", gap: "0.5rem" }}>
          {!event?.isHoliday && (
            <>
              <img 
                src={Edit} 
                alt="edit" 
                style={{ cursor: "pointer", width: "1.5rem", height: "1.5rem" }}
                onClick={() => setIsEditing(!isEditing)}
              />
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ cursor: "pointer" }}
                  onClick={handleDelete}
                />
              )}
            </>
          )}
          <img
            src={CloseX}
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          />
        </div>
        <ContentDiv>
          {isEditing ? (
            <>
              {event.isWorkflowTask ? (
                <>
                  <TaskTitle>{title}</TaskTitle>
                  <TaskDescription>{description}</TaskDescription>
                </>
              ) : (
                <>
                  <TaskTitle 
                    as="input" 
                    type="text" 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{borderBottom: "1px solid #EBEBFD"}}
                  />
                  <TaskDescription
                    as="textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{minHeight: "8.125rem"}}
                  />
                </>
              )}
              <ScheduleDiv>
                <img src={Schedule} alt="schedule" />
                <CustomDateTimePicker
                  label="Date & Time"
                  value={startdatetime}
                  onChange={(newValue) => setStartDatetime(newValue)}
                  disablePast="disablePast"
                />
              </ScheduleDiv>
              <CustomDiv>
                <TaskTitle style={{fontSize: "1.125rem"}}>Recurrence</TaskTitle>
                <ScheduleDiv>
                  <img src={Sort} alt="" />
                  <CustomSelect
                    value={recurrenceType}
                    onChange={(e) => setRecurrenceType(e.target.value)}
                    options={recurrenceOptions}
                  />
                </ScheduleDiv>
                {recurrenceType === 'weekly' && (
                  <ScheduleDiv id="weekly-schedule">
                    <TaskTxt>Repeat on</TaskTxt>
                    <CustomSelect
                      value={selectedWeekDays[0]}
                      onChange={(e) => setSelectedWeekDays([e.target.value])}
                      options={weekDayOptions}
                      renderValue={(selected) => weekDayOptions.find(option => option.value === selected)?.label}
                    />
                  </ScheduleDiv>
                )}
                {recurrenceType === 'monthly' && (
                  <ScheduleDiv id="monthly-schedule">
                    <TaskTxt>On Date</TaskTxt>
                    <CustomSelect
                      value={selectedDates[0]}
                      onChange={(e) => setSelectedDates([e.target.value])}
                      options={dateOptions}
                      renderValue={(selected) => selected.toString()}
                      disabled={isMonthlyDatesDisabled}
                    />
                  </ScheduleDiv>
                )}
              </CustomDiv>
            </>
          ) : (
            <>
              <TaskTitle>{title}</TaskTitle>
              <TaskDescription>{description}</TaskDescription>
              <TaskTxt>Start: {dayjs(event.start).format('MMM D, YYYY h:mm A')}</TaskTxt>
              {/* <TaskTxt>End: {dayjs(event.end).format('MMM D, YYYY h:mm A')}</TaskTxt> */}
              {event.isRecurring && (
                <TaskTxt>
                  Recurrence: {recurrenceType}
                  {recurrenceType === 'weekly' && ` on ${selectedWeekDays.map(day => 
                    weekDayOptions.find(opt => opt.value === day)?.label
                  ).join(', ')}`}
                  {recurrenceType === 'monthly' && ` on date ${selectedDates.join(', ')}`}
                </TaskTxt>
              )}
            </>
          )}
        </ContentDiv>
        {error && (
          <div style={{ color: 'red', textAlign: 'center', margin: '1rem 0' }}>
            {error}
          </div>
        )}
        {isEditing && (
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", marginTop: "auto" }}>
            <UploadTxt 
              style={{ lineHeight: "1.5rem", fontWeight: "600", fontSize: "1rem", cursor: "pointer" }} 
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </UploadTxt>
            {isEditLoading ? (
              <CircularProgress size={24} style={{ margin: '0 1rem' }} />
            ) : (
              <ModalBtn onClick={handleSave}>Save</ModalBtn>
            )}
          </div>
        )}
      </UploadContent>
    </ModalOverlay>
  );
};

export default TaskModal;
