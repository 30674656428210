import React, {useEffect, useState} from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Holidays from "date-holidays";
import { Container, Header, Heading, Content } from "../Summarize/Style";
import BackIcon from "../../assets/back-icon.svg";
import CommentIcon from "../../assets/comment-icon.svg"; 
import PlusIcon from "../../assets/plus-icon-white.svg";
import { TimezoneContainer, TimezoneSelect, Option, HeaderButton, SubTitle, CreateTaskBtn} from "./style";
import { RRule } from 'rrule';
import CustomEvent from "./CustomEvent";
import TaskModal from "../modals/TaskModal";
import AddTaskModal from "../modals/AddTaskModal";
const localizer = momentLocalizer(moment);

const AvatarCalendar = () => {
  const { isSidebarOpen } = useOutletContext();
  const [selectedTimezone, setSelectedTimezone] = useState("NL"); // Default timezone
  const [timezones, setTimezones] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [isTaskModelOpen, setIsTaskModelOpen] = useState(false);
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const navigate = useNavigate();

  // Function to convert API weekday format to RRule weekday
  const getRRuleWeekday = (day) => {
    const weekdayMap = {
      'MO': RRule.MO,
      'TU': RRule.TU,
      'WE': RRule.WE,
      'TH': RRule.TH,
      'FR': RRule.FR,
      'SA': RRule.SA,
      'SU': RRule.SU
    };
    return weekdayMap[day];
  };

  // Function to generate recurring events from API data
  const generateRecurringEvents = (task) => {
    const startYear = new Date().getFullYear();
    const endYear = startYear + 20;
    const recurringEvents = [];

    if (!task.isRecurring) {
      return [{
        id: task._id,
        title: task.taskTitle,
        description: task.taskDescription,
        start: new Date(task.start),
        end: new Date(task.end),
        isRecurring: task.isRecurring,
        recurrenceType: task.recurrenceType,
        byweekday: task.byweekday,
        byweekdayofmonth: task.byweekdayofmonth,
        bydateofmonth: task.bydateofmonth,
        cronExpression: task.cronExpression,
        isWorkflowTask: task.isWorkflowTask,
        scheduleId: task.scheduleId,
        userId: task.userId
      }];
    }

    let ruleOptions = {
      dtstart: new Date(task.start),
      until: moment().year(endYear).endOf('year').toDate()
    };

    switch (task.recurrenceType ? task.recurrenceType.toLowerCase() : '') {
      case 'daily':
        ruleOptions = {
          ...ruleOptions,
          freq: RRule.DAILY,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU]
        };
        break;

      case 'weekly':
        ruleOptions = {
          ...ruleOptions,
          freq: RRule.WEEKLY,
          byweekday: task.byweekday.map(day => getRRuleWeekday(day))
        };
        break;

      case 'monthly':
        ruleOptions = {
          ...ruleOptions,
          freq: RRule.MONTHLY
        };
        
        if (task.byweekdayofmonth && Array.isArray(task.byweekdayofmonth) && task.byweekdayofmonth.length > 0) {
          const weekday = getRRuleWeekday(task.byweekdayofmonth.day);
          ruleOptions.byweekday = [weekday.nth(task.byweekdayofmonth.occurrence)];
        } else if (task.bydateofmonth && task.bydateofmonth.length > 0) {
          ruleOptions.bymonthday = task.bydateofmonth;
        }
        break;
    }

    const rule = new RRule(ruleOptions);
    // Generate instances
    rule.all().forEach(date => {
      recurringEvents.push({
        id: `${task._id}`,
        title: task.taskTitle,
        description: task.taskDescription,
        start: moment(date).toDate(),
        end: moment(date).add(moment(task.end).diff(moment(task.start), 'minutes'), 'minutes').toDate(),
        isRecurring: task.isRecurring,
        recurrenceType: task.recurrenceType,
        byweekday: task.byweekday,
        byweekdayofmonth: task.byweekdayofmonth,
        bydateofmonth: task.bydateofmonth,
        isHoliday: false,
        isWorkflowTask: task.isWorkflowTask,
      });
    });

    return recurringEvents;
  };

  const fetchEvents = async () => {
    try {
      const userId = localStorage.getItem('id');
      const response = await fetch(`https://api.aiflowpro.io/api/task?user_id=${userId}`);
      const data = await response.json();

      if (data.responseCode === 200) {
        const allEvents = data.tasks.flatMap(task => generateRecurringEvents(task));
        console.log("all events", allEvents);
        setEvents(allEvents);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents(); 
  }, [selectedTimezone]);

  useEffect(() => {
    const fetchHolidays = () => {
      const hd = new Holidays();
      const [country, state] = selectedTimezone.split("/");
      hd.init(country, state); 
      const currentYear = new Date().getFullYear();
      const fetchedHolidays = hd.getHolidays(currentYear).map((holiday) => ({
        id: holiday.date,
        title: holiday.name,
        start: new Date(holiday.start),
        end: new Date(holiday.end),
        allDay: true,
        description: holiday.type,
        isHoliday: true
      }));

      setHolidays(fetchedHolidays);
    };

    fetchHolidays();
  }, [selectedTimezone]);

  const combinedEvents = [...events, ...holidays];

  const handleSelectEvent = (event) => {
    setEvent(event);
    setIsTaskModelOpen(true);
  };

  const handleCloseEventDetailModal = () => {
    setIsTaskModelOpen(false);
    setEvent(null);
  };


  const dayPropGetter = (date) => {
    const isHoliday = holidays.some(
      (holiday) =>
        moment(holiday.start).isSame(date, "day") ||
        moment(holiday.end).isSame(date, "day")
    );
    if (isHoliday) {
      return {
        style: {
          backgroundColor: "#FFF3E0", 
        },
      };
    }
    return {};
  };

  useEffect(() => {
    const hd = new Holidays();
    const availableTimezones = [];

    const countries = hd.getCountries();
    Object.keys(countries).forEach((countryCode) => {
      const regions = hd.getStates(countryCode);
      if (regions) {
        Object.keys(regions).forEach((regionCode) => {
          availableTimezones.push({
            label: `${countries[countryCode]} (${regions[regionCode]})`,
            value: `${countryCode}/${regionCode}`,
          });
        });
      } else {
        availableTimezones.push({
          label: countries[countryCode],
          value: countryCode,
        });
      }
    });

    setTimezones(availableTimezones);
  }, []);

  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value); 
  };

  const openAddTaskModal = () => {setIsAddTaskOpen(true);};
  const closeAddTaskModal = () => setIsAddTaskOpen(false);

  return (
    <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
      <Header style={{ alignItems: "center", justifyContent: "space-between", cursor: "default" }}>
        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => navigate("/")}>
          <img src={BackIcon} alt="back" />
          <Heading>Assistant Calendar</Heading>
        </div>
        <TimezoneContainer>
          <SubTitle htmlFor="timezone">Select Timezone</SubTitle>
          <TimezoneSelect id="timezone" value={selectedTimezone} onChange={handleTimezoneChange}>
            <Option disabled>Select a timezone</Option>
            {timezones.map((zone) => (
              <Option key={zone.value} value={zone.value}>
                {zone.label}
              </Option>
            ))}
          </TimezoneSelect>
        </TimezoneContainer>
        <CreateTaskBtn onClick={openAddTaskModal}>
          <img src={PlusIcon} alt="plus" />
          <span>Create Task</span>
        </CreateTaskBtn>
      </Header>
      <Calendar
        className="h-[850px]"
        localizer={localizer}
        events={combinedEvents}
        startAccessor="start"
        endAccessor="end"
        views={["month", "week", "day", "agenda"]}
        formats={{
          dayFormat: "dd",
          monthHeaderFormat: "MMMM yyyy",
        }}
        defaultView="week"
        onSelectEvent={handleSelectEvent}
        components={{
          event: CustomEvent, 
        }}
        dayPropGetter={dayPropGetter}
        style={{ padding: "1rem", height: "92vh"}}
      />
      {isTaskModelOpen && (
        <TaskModal closeModal={handleCloseEventDetailModal} event={event} fetchEvents={fetchEvents} />
      )}
      <style>
        {`
            .rbc-day-slot .rbc-event, .rbc-event, .rbc-day-slot .rbc-background-event, .rbc-event.rbc-selected {
                background-color: #1364FE;
                border: none;
            }
        `}
      </style>
      {isAddTaskOpen && (
        <AddTaskModal closeAddTaskModal= {closeAddTaskModal} fetchEvents={fetchEvents}/>
      )}
    </Container>
  );
};

export default AvatarCalendar;