import React, { useState, useEffect } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import Check from "../../assets/Check.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleCalendarUrl, getGoogleTokenAndUserInfo } from "./apiService";

const Email = ({ integration, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [user, setUser] = useState(null);
  const [emailProvider, setEmailProvider] = useState(null);

  const SCOPES =
    "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.labels profile email";

  const handleUserLogin = async () => {
    setIsLoading(true);
    const payload = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    };
    console.log("Payload", payload);
    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/user/login`,
        payload
      );
      console.log("User Login Response", res.data);
      setUser(res.data);
    } catch (error) {
      console.log("User Login Error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleUserLogin();
  }, []);

  useEffect(() => {
    if (user) {
      handleCheckEmailProvider();
    }
  }, [user]);

  const handleCheckEmailProvider = async () => {
    try {
      const res = await axios.get(
        `https://api.emailflowpro.io/api/provider/user/${user.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Check Email Provider Response", res.data);
      setEmailProvider(res.data.emailProvider);
      if (res.data.emailProvider.length > 0) {
        setIsAuthorized(true);
      }
    } catch (error) {
      console.log("Check Email Provider Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("Token Response Email : ", tokenResponse);
      try {
        const res = await getGoogleTokenAndUserInfo(tokenResponse.code);
        console.log("Google Token and User Info Response", res);
        if (res.isSuccess) {
          setIsAuthorized(true);
          setIsLoading(false);
        } else {
          setIsAuthorized(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log("Google Token and User Info Error", error);
      }
    },
    flow: "auth-code",
    redirectUri: GoogleCalendarUrl.REDIRECT_URI,
    scope: SCOPES,
    onNonOAuthError: () => {
      setIsLoading(false);
    },
  });

  return (
    <>
      <IntegrationCard key={index}>
        <AuthorizedTitle>
          <span
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <span>
              <img src={integration.icon} alt="icon" />
            </span>
            {integration.name}
          </span>
          {isAuthorized && <img src={Check} alt="check" />}
        </AuthorizedTitle>
        <SubText>{integration.description}</SubText>
        <AuthorizeButton
          onClick={() => {
            handleEmailAuth();
            setIsLoading(true);
          }}
          disabled={isLoading || isAuthorized}
          isAuthorized={isAuthorized}
        >
          {isLoading ? (
            <CircularProgress size={13} color="inherit" />
          ) : isAuthorized ? (
            "Authorized"
          ) : (
            "Authorize"
          )}
        </AuthorizeButton>
      </IntegrationCard>
    </>
  );
};

export default Email;
