import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Header,
  Heading,
  UploadBtn,
  Content,
  CenterPane,
  QuestionTxt,
  InputContainer,
  Input,
  SubHeading,
  ChatTxt,
  GridContainer,
  ScrollableChat,
  LoadingText,
  ClearButton,
  SourceDiv,
  SourceTxt,
} from "./Style";
import GenSearchSideBar from "../Sidebar/GenSearchSideBar";
import BackIcon from "../../assets/back-icon.svg";
import ChatDivider from "../../assets/chat-divider.svg";
import CloseX from "../../assets/x-close.svg";
import FileIcon from "../../assets/file-icon.svg";
import { useOutletContext, useNavigate } from "react-router-dom";
import ArrowUpIcon from "../../assets/arrow-up.svg";
import SourceCard from "../Cards/SourceCard";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import { CircularProgress } from "@mui/material";
import ClearIcon from "../../assets/delete-icon-white.svg";
import ReactMarkdown from 'react-markdown';


const GeneralSearch = () => {
  const { isSidebarOpen } = useOutletContext();
  const [isSearchSidebarVisible, setSearchSidebarVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [apiloading, setApiLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(""); // Added state for selected document
  const [isSourceDivVisible, setSourceDivVisible] = useState(false); // Added state for SourceDiv visibility
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("id");
  const scrollableChatRef = useRef(null);

  useEffect(() => {
    fetchChatHistory();
  }, [userId]); 

  const fetchChatHistory = async () => {
    try {
      const response = await fetch(
        `https://api.aiflowpro.io/api/auth/get-chats?user_id=${userId}`
      );
      const data = await response.json();

      const chatHistory = data.chats.map((chat) => [
        { role: "USER", content: chat.query, sourceDocuments: chat.sourceDocuments, sourceChunks: chat.sourceChunks},
        { role: "ASSISTANT", content: chat.content },
      ]).flat();

      setMessages(chatHistory);
    } catch (error) {
      console.error("Failed to load chat history:", error);
    } finally {
      setLoadingHistory(false);
    }
  };

  const handleSendMessage = async () => {
    const userName = localStorage.getItem("name");
    if (!userMessage.trim()) return;

    const newMessages = [...messages, { role: "USER", content: userMessage, sourceDocuments: [] }];

    setMessages(newMessages);
    setLoading(true);
    setUserMessage("");

    try {
      const response = await fetch(
        `https://api.aiflowpro.io/api/auth/get-training-status?user_id=${userId}`
      );
      const data = await response.json();

      if (data.isTrained) {
        const queryResponse = await fetch(
          "https://flowproai.dialect-ai.com/ai_flow_pro/query_multidoc",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: userId,
              user_name: userName,
              source_document_id: selectedDocument,
              messages: newMessages,
            }),
          }
        );

        const queryData = await queryResponse.json();
        const documentNames = queryData.source_documents.map(doc => doc.document_name);
        const documentChunks = queryData.source_documents.map(doc => doc.document_chunk);
        const updatedMessages = [
          ...newMessages,
          { role: "USER", sourceDocuments:documentNames, sourceChunks: documentChunks },
          { role: "ASSISTANT", content: queryData.content, sourceDocuments: documentNames, sourceChunks: documentChunks},
        ];
        setMessages(updatedMessages);

        if (!isSourceDivVisible) {  // Only call the API if SourceDiv is not visible
          await fetch("https://api.aiflowpro.io/api/auth/create-chat", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: userId,
              user_name: userName,
              query: userMessage,
              content: queryData.content,
              sourceDocuments: documentNames,
              sourceChunks: documentChunks
            }),
          });
        }

      } else {
        const message =
          data.trainingStatus ===
          "Embeddings are being created in the background."
            ? "Your model is training. Wait a few seconds and then send a message."
            : "No training files! Upload some files first";
        setMessages([...newMessages, { role: "ASSISTANT", content: message }]);
      }
    } catch (error) {
      setMessages([
        ...newMessages,
        { role: "ASSISTANT", content: "An error occurred. Please try again." },
      ]);
    } finally {
      setLoading(false);
    }
  };


  const handleClearChat = async () => {
    setApiLoading(true);
    const userId = localStorage.getItem("id");
    try {
      const response = await fetch("https://api.aiflowpro.io/api/auth/delete-chats", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
                                         
      const data = await response.json();
      if (data.responseCode === 200) {
        setSnackbarMessage(data.message);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setMessages([]);
      } else {
        console.error("Failed to delete chats:", data.message);
      }
    } catch (error) {
      setSnackbarMessage("Failed to delete chats for this user");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setApiLoading(false);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 1000); // Close the modal after the operation
    }
  };

  const handleSourceCardClick = (documentName) => {
    console.log("doc name", documentName );
    setSelectedDocument(documentName);
    setSourceDivVisible(true);
    setMessages([]); // Clear messages on SourceCard click
  };

  const handleCloseSourceDiv = () => {
    setSourceDivVisible(false);
    setSelectedDocument("");
    fetchChatHistory(); // Fetch chat history on closing SourceDiv
  };

  useEffect(() => {
    if (scrollableChatRef.current) {
      scrollableChatRef.current.scrollTop =
        scrollableChatRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <Container isOpen={isSidebarOpen} style={{marginRight: "1.4rem"}}>
        <Header
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }} onClick={() => navigate("/")}>
            <img src={BackIcon} alt="back" />
            <Heading>General Search</Heading>
          </div>
          <UploadBtn onClick={() => setSearchSidebarVisible(true)}>
            <img src={FileIcon} alt="Manage Files" style={{height:"1.5rem"}}/>
            Manage Files
          </UploadBtn>
        </Header>
        <Content>
          <ClearButton style={{position: "absolute", background: "#0062FF", color: "#FFF", padding:"0.4625rem 1rem", top: "1rem", right: "1rem"}} onClick={handleClearChat}> 
            {!apiloading ? 
              <>
                <img src={ClearIcon} alt="clear" style={{height: "1.2rem"}} />   
                Clear Chat
              </> 
              :
              <CircularProgress size={21} color="inherit" />
            }
          </ClearButton>
          <CenterPane>
            {isSourceDivVisible && (
              <SourceDiv>
                <div style={{display: "flex"}}>
                  <SourceTxt>You are interacting with {selectedDocument}</SourceTxt>
                  <img src={CloseX} alt="close" style={{cursor: "pointer", marginLeft: "auto"}} onClick={handleCloseSourceDiv} />
                </div>
              </SourceDiv>
            )}
            {loadingHistory ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="circular-progress"></div>
              </div>
            ) : (
              <ScrollableChat ref={scrollableChatRef}>
                {messages.map((msg, index) => (
                  <div key={index}>
                    {msg.role === "USER" ? (
                      <div>
                        <QuestionTxt>{msg.content}</QuestionTxt>
                        {msg.sourceDocuments && msg.sourceDocuments.length > 0 && (
                          <>
                            {!isSourceDivVisible && (<SubHeading>Sources</SubHeading>)}
                            <GridContainer isOpen={isSidebarOpen} style={{ display: isSourceDivVisible ? 'none' : 'grid' }}>
                              {msg.sourceDocuments.map((documentName, index) => (
                                <SourceCard
                                  key={index}
                                  cardText={documentName}
                                  documentType={documentName.split('.').pop()}
                                  chunk={msg.sourceChunks && msg.sourceChunks[index] ? msg.sourceChunks[index] : ''}
                                  onClick={() => handleSourceCardClick(documentName)}
                                />
                              ))}
                            </GridContainer>
                          </>
                        )}                        
                      </div>
                    ) : (
                      <div style={{width: "100%"}}>
                        <SubHeading>Answer</SubHeading>
                        <ChatTxt>
                          <ReactMarkdown>{msg.content}</ReactMarkdown>
                        </ChatTxt>
                        <img src={ChatDivider} alt="" style={{width:"100%"}}/>
                      </div>
                    )}
                  </div>
                ))}
                {loading && <LoadingText style={{fontSize: "0.875rem", marginBottom: "1rem", lineHeight: "1.5rem"}}/>}
              </ScrollableChat>
            )}
            <InputContainer>
              <Input
                type="text"
                placeholder="Ask a question"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
              <img
                src={ArrowUpIcon}
                alt="send message"
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={handleSendMessage}
              />
            </InputContainer>
          </CenterPane>
        </Content>
        {isSearchSidebarVisible && (
          <GenSearchSideBar
            ref={sidebarRef}
            onClose={() => setSearchSidebarVisible(false)}
            onUpdate={handleClearChat}
          />
        )}
        {!isSearchSidebarVisible && (
          <SnackbarAlert
            open={snackbarOpen}
            setOpen={setSnackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        )}
      </Container>
    </>
  );
};

export default GeneralSearch;