import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import axios from "axios";
import { ContentDiv, CardText, Heading } from "./Style";

const Tour = ({ run, handleClose }) => {
  const steps = [
    {
      target: ".nav-item-0", // Target the first nav item
      disableBeacon: true,
      hideBackButton: true,
      content: (
        <ContentDiv>
          <CardText>1/3</CardText>
          <Heading>AI Assistant</Heading>
          <CardText style={{ fontWeight: "400", textAlign: "left" }}>
          Head over to the assistant to start chatting and get things done! Your assistant seamlessly interacts with all the tools to help you accomplish your tasks efficiently.
          </CardText>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <CardText>Not your first time?</CardText>
            <CardText
              onClick={handleClose}
              style={{ fontWeight: "600", textDecorationLine: "underline" }}
            >
              Skip tutorial
            </CardText>
          </div>
        </ContentDiv>
      ),
    },
    {
      target: ".grid-item-0", // Target the second nav item
      hideBackButton: true,
      content: (
        <ContentDiv>
          <CardText>2/3</CardText>
          <Heading>Start App</Heading>
          <CardText style={{ fontWeight: "400", textAlign: "left" }}>Click the “Start App” button to access any app, including email automation, meetings, to-dos, and more!
          </CardText>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <CardText>Not your first time?</CardText>
            <CardText
              onClick={handleClose}
              style={{ fontWeight: "600", textDecorationLine: "underline" }}
            >
              Skip tutorial
            </CardText>
          </div>
        </ContentDiv>
      ),
    },
    {
      target: ".nav-item-settings", // Target the third nav item
      hideBackButton: true,
      content: (
        <ContentDiv>
          <CardText>3/3</CardText>
          <Heading>Explore Settings</Heading>
          <CardText style={{ fontWeight: "400", textAlign: "left" }}>
            Manage your password, profile, payment method, transaction history, and control your subscription plan
          </CardText>
          <div style={{ display: "flex", marginTop: "2rem" }}>
            <CardText>Not your first time?</CardText>
            <CardText
              onClick={handleClose}
              style={{ fontWeight: "600", textDecorationLine: "underline" }}
            >
              Skip tutorial
            </CardText>
          </div>
        </ContentDiv>
      ),
    },
  ];

  const handleApiCall = () => {
    const email = localStorage.getItem("email");
    if (email) {
      axios
        .post("https://api.aiflowpro.io/api/auth/onboard-user", { email })
        .then((response) => {
          console.log("API response:", response.data);
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    } else {
      console.error("Email not found in local storage");
    }
  };

  const handleTourCallback = (data) => {
    const { status, action } = data;
    if (status === "finished" || action === "skip") {
      handleClose();
      handleApiCall();
    }
  };

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous={true}
      callback={handleTourCallback}
      styles={{
        options: {
          zIndex: 10000,
          width: "25rem",
          backgroundColor: "#0062FF",
          arrowColor: "#0062FF",
          textColor: "#FFF",
          beaconSize: 0,
        },
        buttonNext: {
          display: "flex",
          padding: "0.625rem 1.125rem",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          borderRadius: "6.25rem",
          background: "#FFF",
          color: "#313133",
        },
        spotlight: {
          borderRadius: "0.75rem",
        },
      }}
    />
  );
};

export default Tour;