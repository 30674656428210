import styled, { keyframes } from "styled-components";

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${({ isOpen }) => (isOpen ? "17.5rem" : "3.75rem")};
  border: 1px solid #e2e3e5;
  background: #fff;
  position: relative;
  transition: width 0.3s ease, visibility 0.3s ease;
  visibility: visible;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    z-index: ${({ isOpen }) => (isOpen ? "1" : "auto")};
    position: ${({ isOpen }) => (isOpen ? "fixed" : "static")};
  }

  @media (min-height: 850px) {
    position: fixed;
    z-index: 5;
    height: 100vh;
    border-bottom: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const LogoIcon = styled.img`
  margin-top: 2.44rem;
  align-items: center;
  cursor: pointer;
`;

export const Caption = styled.div`
  color: #8b9ab1;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.035rem;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  width: 100%;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 1.1rem;
`;

export const NavItem = styled.div`
  display: flex;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  width: ${({ isOpen }) => (isOpen ? "80%" : "55%")};
  position: ${({ isOpen }) => (isOpen ? "" : "relative")};
  box-shadow: ${({ isActive }) =>
    isActive ? "0px 14px 10px 0px rgba(127, 127, 127, 0.30)" : "none"};
  cursor: ${({ hasRoute }) => (hasRoute ? "pointer" : "default")};
  transition: all 0.2s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  &:hover {
    background: ${({ hasRoute }) => (hasRoute ? "#FFF" : "transparent")};
    box-shadow: ${({ hasRoute }) =>
      hasRoute ? "0px 14px 10px 0px rgba(127, 127, 127, 0.30)" : "none"};
  }
`;

export const ItemImg = styled.img`
  display: flex;
  width: ${({ isOpen }) => (isOpen ? "1.5rem" : "1.25rem")};
  height: ${({ isOpen }) => (isOpen ? "1.5rem" : "1.25rem")};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(180deg)" : "rotate(0deg)"};

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 15px;
    height: 15px;
  }
`;

export const ItemText = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const DividerDiv = styled.img`

`;

export const TeamStorage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  width: 70%;
`;

export const ToggleButton = styled.div`
  position: absolute;
  top: 3.81rem;
  right: -0.7rem;
  cursor: pointer;
  cursor: pointer;
`;

export const UserDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 1.25rem 0rem 1.25rem 0rem;
`;

export const UserText = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.045rem;
  margin-left: 0.58rem;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const More = styled.img`
  transform: rotate(180deg);
  margin-left: 1.25rem;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  cursor: pointer;
`;

export const Coming = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.875rem;
  height: 2.375rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  text-align: center;
  background: #f1f1f5;
  color: #696974;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 2.06rem;

  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const Popup = styled.div`
  position: absolute;
  top: -8.5rem;
  right: -3rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure the popup is above other content */
  padding: 0.5rem;
  width: 12rem; /* Adjust width as needed */
  border-radius: 0.5rem;
  display: ${(props) =>
    props.isOpen
      ? "block"
      : "none"}; /* Conditionally show/hide based on isOpen prop */
`;

export const PopupItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

//Gen Search Sidebar styling
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 48.5rem;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #fff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
  margin-left: auto;
  position: fixed;
  top: 0;
  right: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateX(100%);
  animation: ${slideIn} 0.5s forwards;
  overflow-y: auto;
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px rgba(9, 9, 9, 0.1);
  padding: 1rem 1.5rem;
`;

export const SidebarHeader = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 140% */
`;

export const SidebarBtn = styled.div`
  margin-left: 1rem;
  display: flex;
  padding: 0.5625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #0062ff;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.87rem 1rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  background: #f6f7f9;
`;

export const TableText = styled.div`
  color: #8b9ab1;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02625rem;
`;

export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1.5rem 1rem;
  border: 1px solid #ebebfd;
`;

export const RowText = styled.div`
  color: #7c8da7;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const DeleteDiv = styled.div`
  display: flex;
  height: 2.8rem;
  padding: 0.2rem 1rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fdedec;
  margin: 0rem 1rem;
  align-items: center;
`;

export const DeleteTxt = styled.div`
  color: #e5322d;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const Counter = styled.div`
  display: flex;
  position: ${({ isOpen }) => (isOpen ? "" : "absolute")};
  margin-left: auto;
  padding: 0.25rem 0.5rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: #e41d57;
  color: var(--Neutral-10, var(--Brand-White, #fff));
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00625rem;
  top: 0;
  right: 0;
`;
