import React, { useState, useEffect } from "react";
import AvatarChatBg from "../../assets/avatar_chat_bg.png";
import EllipsisIcon from "../../assets/workflows/ellipse-icon.svg";
import SquarePenIcon from "../../assets/workflows/square-pen-icon.svg";
import TrashIcon from "../../assets/workflows/trash-icon.svg";
import { useOutletContext } from "react-router-dom";
import {
  Container,
  ContainerContent,
  Header,
  HeaderName,
  Button,
  WorkflowsContainer,
  WorkflowCardContainer,
  WorkflowsHeader,
  WorkflowName,
  WorkflowDescription,
  WorkflowStatus,
  WorkflowCreatedAt,
  WorkflowActions,
} from "./style";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";

const Workflows = () => {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentWorkflow, setCurrentWorkflow] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, workflow) => {
    setAnchorEl(event.currentTarget);
    setCurrentWorkflow(workflow);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/workflows/create?workflow_id=${currentWorkflow._id}`);
    handleClose();
  };

  const fetchWorkflows = async () => {
    try {
      setLoading(true);
      setWorkflows([]);
      const response = await fetch(
        "https://api.aiflowpro.io/api/workflow/getworkflows?user_id=" +
          localStorage.getItem("id"),
        {
          method: "GET",
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        handleSnackbar("success", "Workflows fetched successfully");
        setWorkflows(data.workflows);
      } else {
        handleSnackbar("error", "Failed to fetch workflows");
      }
    } catch (error) {
      console.log("error : ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  const handleSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleDelete = async () => {
    try {
      const payload = {
        workflowIds: [currentWorkflow._id],
      };
      const response = await fetch(
        "https://api.aiflowpro.io/api/workflow/delete",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        handleSnackbar("success", data.message);
        fetchWorkflows();
      } else {
        handleSnackbar("error", data.message);
      }
    } catch (error) {
      console.log("error : ", error);
    } finally {
      handleClose();
    }
  };

  return (
    <Container
      isOpen={isSidebarOpen}
      style={{
        marginRight: "0rem",
        backgroundImage: `url(${AvatarChatBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div style={{ height: "calc(100vh - 4rem)", padding: "2rem" }}>
        <ContainerContent>
          <Header>
            <HeaderName>Workflows</HeaderName>
            <Button onClick={() => navigate("/workflows/create")}>
              Create Workflow
            </Button>
          </Header>
          <WorkflowsContainer>
            <WorkflowsHeader>
              <WorkflowName
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                Name
              </WorkflowName>
              <WorkflowDescription
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                Description
              </WorkflowDescription>
              <WorkflowStatus
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                Status
              </WorkflowStatus>
              <WorkflowCreatedAt
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                Created At
              </WorkflowCreatedAt>
              <WorkflowActions
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                Actions
              </WorkflowActions>
            </WorkflowsHeader>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <CircularProgress
                  style={{ color: "#36383c", width: "20px", height: "20px" }}
                />
              </div>
            ) : workflows.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                  color: "#36383c",
                }}
              >
                No workflows found.
              </div>
            ) : (
              <div style={{ overflowY: "auto" }}>
                {workflows.map((workflow) => (
                  <WorkflowCard
                    key={workflow.id}
                    workflow={workflow}
                    handleClick={(event) => handleClick(event, workflow)}
                  />
                ))}
              </div>
            )}
          </WorkflowsContainer>
        </ContainerContent>
      </div>
      <SnackbarAlert
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={handleEdit}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#36383c",
          }}
        >
          <img width={16} height={16} src={SquarePenIcon} alt="edit" />
          Edit/View
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#36383c",
          }}
        >
          <img width={16} height={16} src={TrashIcon} alt="delete" /> Delete
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Workflows;

export const WorkflowCard = ({ workflow, handleClick }) => {
  return (
    <WorkflowCardContainer>
      <WorkflowName>
        <p>{workflow.name}</p>
      </WorkflowName>
      <WorkflowDescription>
        <p>{workflow.description}</p>
      </WorkflowDescription>
      <WorkflowStatus>
        <p>{workflow.status ? "Active" : "Inactive"}</p>
      </WorkflowStatus>
      <WorkflowCreatedAt>
        <p>{new Date(workflow.createdAt).toLocaleDateString()}</p>
      </WorkflowCreatedAt>
      <WorkflowActions>
        <img
          onClick={(event) => handleClick(event)}
          src={EllipsisIcon}
          alt="ellipsis"
        />
      </WorkflowActions>
    </WorkflowCardContainer>
  );
};
