import styled from "styled-components";
import { Popover, Box, TextField } from "@mui/material";

export const PopoverContent = styled(Box)`
  padding: 1.25rem 1rem;
  width: 17.9375rem;
  background: #fff;
  border-radius: 1rem;
`;

export const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.44rem;
  color: #fff;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 111.111% */
  border-radius: 1rem;
  background: #1a74db;
  cursor: pointer;
  &:hover {
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const SaveTxt = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 111.111% */
`;

export const PopoverHdr = styled(Box)`
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
`;
