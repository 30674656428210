// AvatarSelection.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  PageContainer,
  Title,
  Description,
  AvatarContainer,
  AvatarImage,
  NextButton,
  CarouselContainer,
  AvatarDiv,
} from "./style";
import { AVATARS } from "../../Components/Constants/Constants";

const AvatarSelection = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(2); // Default to middle avatar
  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleAvatarClick = (index) => {
    setSelectedAvatar(index);
  };

  const handleNextClick = () => {
    const selectedImageId = AVATARS[selectedAvatar].imageId;
    navigate("/avatar-info", { state: { imageId: selectedImageId } });
  };

  return (
    <PageContainer>
      <AvatarDiv>
        <Title>Select Avatar</Title>
        <Description>
          "Choose your AI assistant and watch it work wonders for you! 🚀"
        </Description>
        <CarouselContainer>
          <Carousel
            responsive={responsive}
            infinite={true}
            centerMode={true}
            //   removeArrowOnDeviceType={["tablet", "mobile"]}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            containerClass="carousel-container"
            dotListClass="carousel-dots"
            itemClass="carousel-item"
          >
            {AVATARS?.slice(0, 3).map((avatar, index) => (
              <AvatarContainer
                key={index}
                onClick={() => handleAvatarClick(index)}
                isSelected={selectedAvatar === index}
              >
                <AvatarImage src={avatar.src} alt={avatar.alt} />
              </AvatarContainer>
            ))}
          </Carousel>
        </CarouselContainer>
        <NextButton onClick={handleNextClick}>Next</NextButton>
      </AvatarDiv>
    </PageContainer>
  );
};

export default AvatarSelection;
