import styled from "styled-components";

export const IntegrationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.375rem, 1fr));
  gap: 1rem;
  width: 100%;
`;

export const IntegrationCard = styled.div`
  padding: 1rem;
  border-radius: 0.625rem;
  border: 0px solid #030303;
  background: #f4f6f6;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #171725;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 122.222% */
  letter-spacing: 0.00625rem;
`;

export const AuthorizedTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #171725;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 122.222% */
  letter-spacing: 0.00625rem;
`;

export const AuthorizeButton = styled.button`
  margin-top: auto;
  border-radius: 0.625rem;
  background: ${(props) => (props.isAuthorized ? "#3ED598" : "#007BFF")};
  color: white;
  border: none;
  padding: 0.75rem;
  cursor: pointer;
  color: #fafafb;
  text-align: center;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  &:hover {
    background: ${(props) => (props.isAuthorized ? "#3ED598" : "#3381ff")};
  }
`;

export const SubText = styled.div`
  color: #8b9ab1;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
  letter-spacing: 0.00625rem;
`;
