import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
// import ChatPopup from "../../Components/Avatar/ChatPopup";
import OnboardModal from "../../Components/modals/OnboardModal";
import Tour from "../../Components/Onboarding/Tour";
import { notification } from "antd";

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [notifUpdated, setNotifUpdated] = useState(false);
  const [showOnboardModal, setShowOnboardModal] = useState(false);
  const [runTour, setRunTour] = useState(false);

  useEffect(() => {
    const isOnboarded = localStorage.getItem("isOnboarded");
    if (isOnboarded === "false") {
      setShowOnboardModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    setShowOnboardModal(false);
    localStorage.setItem("isOnboarded", "true");
  };

  const handleCloseTour = () => {
    setRunTour(false);
    navigate("/avatar-chat");
  };

  const isAvatarChatPage = location.pathname === "/avatar-chat";

  return (
    <div style={{ display: "flex", background: "#FDFDFD", maxHeight: isAvatarChatPage ? "100vh" : "auto" }}>
      <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} profileUpdated={profileUpdated} setProfileUpdated={setProfileUpdated} notifUpdated={notifUpdated} setNotifUpdated={setNotifUpdated}/>
      <div style={{ width: "100%", overflow: "auto" }}>
        <Outlet context={{ isSidebarOpen, setIsSidebarOpen, profileUpdated, setProfileUpdated, notifUpdated, setNotifUpdated }} />
      </div>   
      {/* {!isAvatarChatPage && <ChatPopup />} */}
      {showOnboardModal && ( <OnboardModal setRunTour={setRunTour} onClose={handleCloseModal} /> )}
      {runTour && ( <Tour run={runTour} handleClose={handleCloseTour}/> )}
    </div>
  );
}