import styled, { keyframes } from "styled-components";

export const AvatarSection = styled.div`
  // position: absolute;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  // justify-content: center;
  // top: 1.5rem;
  // right: 1.5rem;
  cursor: pointer;
`;

export const AssistantName = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.00625rem;
`;

// export const AssistantTitle = styled.h3`
//   color: rgba(255, 255, 255, 0.6);
//   text-align: center;
//   font-family: Roboto;
//   font-size: 0.875rem;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 1.5rem; /* 171.429% */
//   letter-spacing: 0.00625rem;
// `;

export const ChatBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const ChatDiv = styled.div`
  overflow: hidden; /* By default, hide overflow */
  max-height: 100%; /* Set a max height to control when scrolling kicks in */
  overflow-y: scroll; /* Enable vertical scroll when hovering */
  flex: 1;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 0.75rem;
  justify-content: ${({ isUser }) => (isUser ? "flex-end" : "flex-start")};
  margin-bottom: 0.75rem;
  width: 100%;
`;

export const MessageBubble = styled.div`
  // max-width: 80%;
  padding: 0.875rem;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  border-radius: 0.75rem;
  // font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
`;

export const InputContainer = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  // border-radius: 6.25rem;
  // background: rgba(0, 0, 0, 0.15);

  border-radius: 16px;
  border: 2px solid #e8ecef;
  background: #fff;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  color: #000;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  width: 100%;
  background: transparent;
  &::placeholder {
    color: #93989a !important; /* Add !important to ensure it's not overridden */
    opacity: 0.6;
  }

  &::-webkit-input-placeholder {
    color: #93989a !important;
  }

  &::-moz-placeholder {
    color: #93989a !important;
  }

  &:-ms-input-placeholder {
    color: #93989a !important;
  }

  &::-ms-input-placeholder {
    color: #93989a !important;
  }
`;

const loading = keyframes`
  0% {
    content: 'Typing';
  }
  33% {
    content: 'Typing.';
  }
  66% {
    content: 'Typing..';
  }
  100% {
    content: 'Typing...';
  }
`;

export const LoadingText = styled.div`
  color: ${({ textColor }) => textColor};
  border-radius: 0.75rem;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  &:after {
    content: "Loading";
    animation: ${loading} 1.5s infinite;
  }
`;

export const FileDiv = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
  width: 95%;
  overflow-y: auto;
  scrollbar-width: none;
`;

export const ChatFileDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
  align-items: flex-end;
`;
//Chat Popup styling

export const PopupContainer = styled.div`
  position: fixed;
  bottom: 2.37rem;
  right: 3.12rem;
  z-index: 50;
  @media (max-width: 768px) {
    bottom: 1rem;
    right: 1rem;
  }
`;

export const ChatWindow = styled.div`
  margin-bottom: 16px;
  width: 26.4375rem;
  background: white;
  border-radius: 1.125rem;
  /* shadows/shadow-widget */
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.08),
    5px 14px 80px 0px rgba(26, 26, 26, 0.12);
  overflow: hidden;
  @media (max-width: 455px) {
    width: 96%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  background: #0062ff;
  color: white;
  padding: 1.25rem;
  gap: 0.88rem;
  color: var(--header-title, #f8f8f8);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 100% */
`;

export const HeaderTitle = styled.h3`
  font-weight: 500;
  margin: 0;
`;

export const MessagesContainer = styled.div`
  height: 32rem;
  overflow-y: auto;
  padding: 1.25rem 1.25rem 0rem 1.25rem;
  background: white;
  @media (max-height: 800px) {
    height: 25rem;
  }
  @media (max-height: 700px) {
    height: 20rem;
  }
`;

export const InputArea = styled.div`
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 0.5rem;
  svg {
    color: #6b7280;
    cursor: pointer;

    &:hover {
      color: #374151;
    }
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: #000;

  &::placeholder {
    color: #9ca3af;
  }
`;

export const ToggleButton = styled.button`
  background: #2563eb;
  border-radius: 9999px;
  padding: 12px;
  color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: none;
  cursor: pointer;

  &:hover {
    background: #1d4ed8;
  }
`;

export const PopupIconImage = styled.img`
  transition: transform 0.3s ease;

  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const MsgContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  margin-bottom: 1rem;
  gap: 0.62rem;
  align-items: flex-start;
`;

export const MsgBubble = styled.div`
  max-width: 70%;
  padding: 1rem 1.125rem;
  border-radius: 0.625rem;
  background: #f1f1f5;
  font-family: Roboto;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  ${(props) =>
    props.isUser
      ? `
    background: #2563eb;
    color: #FFF;
  `
      : `
    background: #f3f4f6;
    color: #000;
  `}
`;

export const EditImg = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const UploadDiv = styled.div`
  display: inline-flex;
  padding: 0.5625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background: #f1f1f5;
  color: #696974;
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  cursor: pointer;
  border: none;
  outline: none;
  &:hover {
    background-color: #e0e0e5; /* Slightly darker on hover */
  }
  border-bottom: 1px solid #e0e0e5;
`;

//Suggestions Styling
export const SuggestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  // margin-bottom: ${(props) => (props.isPopup ? "0.5rem" : "0.8rem")};
  padding: ${(props) => (props.isPopup ? "0rem" : "0 1rem")};
  margin-left: 0.5rem;
`;

export const SuggestionButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.75rem;
  background: ${(props) => (props.isPopup ? "#8B9AB1" : "rgba(0, 0, 0, 0.3)")};
  padding: ${(props) => (props.isPopup ? "0.5rem" : "1rem")};
  color: ${(props) => (props.isPopup ? "#FFF" : "#ececec")};
  // font-family: Poppins;
  font-size: ${(props) => (props.isPopup ? "0.75rem" : "0.85rem")};
  font-style: normal;
  font-weight: ${(props) => (props.isPopup ? "400" : "500")};
  line-height: 1.25rem; /* 142.857% */
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  flex: 1;
  min-width: calc(50% - 0.5rem);
  max-width: calc(50% - 0.5rem);

  &:hover {
    background-color: ${(props) =>
      props.isPopup ? "#A0B1C1" : "rgba(255, 255, 255, 0.25)"};
    color: #202226;
  }
`;

//Calendar Styling
export const TimezoneContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Label = styled.label`
  font-size: 1rem;
  margin-right: 0.5rem;
  color: #333;
`;

export const TimezoneSelect = styled.select`
  appearance: none;
  background-color: #fff;
  border-radius: 0.625rem;
  border: 1px solid #dcdbdd;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  cursor: pointer;
  transition: border 0.3s ease;
  width: 250px; /* adjust based on preference */
  text-align: center;
  &:hover {
    background: #f2f4f8;
  }
`;

export const CreateTaskBtn = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.625rem;
  background: #0062ff;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &:hover {
    background: #3381ff;
  }
`;

export const Option = styled.option`
  color: #333;
  background-color: #fff;
  justify-content: space-between;
`;

export const HeaderButton = styled.div`
  margin-right: 1rem;
  display: inline-flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &:hover {
    background: #f2f4f8;
  }
`;

export const SubTitle = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 760px) {
    font-size: 0.75rem;
  }
`;

//avatar chat styling

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  // min-height: 47.25rem;
  margin-right: 0rem;
  margin-left: ${({ isOpen }) => (isOpen ? "17.50rem" : "3.8rem")};
  @media (max-height: 850px) {
    margin-left: 0rem;
  }
`;

export const InnerContainer = styled.div`
  padding: 0 3rem 0;
  @media (max-width: 1400px) {
    padding: 0 2.5rem 0;
  }
  @media (max-width: 1200px) {
    padding: 0 2rem 0;
  }
  @media (max-width: 992px) {
    padding: 0 1.5rem 0;
  }
  @media (max-width: 768px) {
    padding: 0 1rem 0;
  }
`;

export const ContentContainer = styled.div`
  padding: 1rem;
`;

export const Content = styled.div`
  position: relative;
  justify-content: center;
  display: flex;

  border-radius: 31px;
  border: 1px solid #fff;
  backdrop-filter: blur(40px);
  width: 100%;
`;

export const NotificationSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.56);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid #ffffff;
  }
`;

export const TopNavbarSection = styled.div`
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40px);
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  @media (min-width: 992px) {
    margin-top: 1.5rem;
    width: 75%;
  }
`;

export const TopNavItem = styled.div`
  display: flex;
  padding: 10px 20px;
  border-radius: 32px;
  background: transparent;
  cursor: pointer;

  color: #fbfbfb;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */

  transition: all 0.3s ease;
  &:hover {
    background: #ffffff;
    color: #3e473e;
  }

  ${(props) =>
    props.isActive &&
    `
    background: #ffffff;
    color: #3e473e;
  `}
`;

export const AddDocument = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background: #f2f4f8;
  }
`;

export const SendMessageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #202226;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
`;

export const RightNavbarSection = styled.div`
  border-radius: 33.5px;
  border: 1px solid #fff;
  // background: rgba(255, 255, 255, 0.2);
  background: transparent !important;
  backdrop-filter: blur(10px);

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const BelowNavbarSection = styled.div`
  border-radius: 33.5px;
  background: rgba(17, 17, 17, 0.2);
  backdrop-filter: blur(10px);

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const RightNavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid #fff;
  }
`;

export const UserName = styled.div`
  color: #202226;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */
`;

export const TimeStamp = styled.div`
  color: #6a6d7a; // Darkened color
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
`;

//workflow builder styling
export const WorkFlowHdr = styled.div`
  width: max-content;
  display: flex;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background: #fff;
  backdrop-filter: blur(40px);
  color: #3e473e;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 100% */
  letter-spacing: -0.01rem;
`;

export const MainContnr = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ActionButton = styled.div`
  width: max-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
  background: #1a7adb;
  border-radius: 1.125rem;
  justify-content: center;
  align-items: center;
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  cursor: pointer;
  &:hover {
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const BottomActionBar = styled.div`
  width: max-content;
  margin-top: auto;
  gap: 3.56rem;
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  border-radius: 6.25rem;
  background: #fff;
  // &:hover {
  //
  // }
  transition: padding 0.3s ease;
`;

export const ExploreBtn = styled.div`
  position: absolute;
  bottom: 40px;
  right: 4.69rem;
  width: max-content;
  padding: 22.8px 2rem;
  display: flex;
  background: #000;
  border-radius: 2.8125rem;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Poppins";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

//Skill Section Styling
export const SkillsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16.34rem, 1fr));
  gap: 1.25rem;
  // width: 100%;
  height: fit-content;
  padding-bottom: 1rem;
`;

export const SkillCard = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.2s ease;
  // max-height: max-content;
  &:hover {
    transform: translateY(-2px);
  }
`;

export const SkillHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 125% */
`;

export const SkillIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    color: #1a1a1a;
  }
`;

export const SkillName = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
`;

export const RunButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  border-radius: 1rem;
  background: #1a74db;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 0.4375rem 1.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: fit-content;
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 112.5% */
  &:hover {
    background-color: #0052cc;
  }
`;

export const Tag = styled.div`
  display: flex;
  width: max-content;
  padding: 0.1875rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: rgba(26, 116, 219, 0.2);
  color: #1a74db;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
`;

export const SearchContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: max-content;
`;