import React, { useState } from "react";
import { Button, Input, Select, Upload, Form, Row, Col, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import BackIcon from "../../assets/back-icon.svg";
import { Container, Content, Header, Heading } from "./Style";
import s3 from "../Environment/Asconfig";

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;

const uploadFileToS3 = async (file) => {
  const selectedUserId = localStorage.getItem("id");
  const folderName = `${selectedUserId}/documents`;
  const key = `${folderName}/${file.name}`;
  const params = {
    Bucket: "aiflowpro-fe-userdata",
    Key: key,
    Body: file,
  };
  const data = await s3.upload(params).promise();
  return data.Location;
};

export default function AddSkills() {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Handle file selection in the Dragger component
  const handleFileChange = ({ fileList }) => {
    setSelectedFiles(fileList.map((file) => file.originFileObj));
  };

  const handleFinish = async (values) => {
    setLoading(true);
    const fileUrls = [];
    const id = localStorage.getItem("id");

    // Upload files to S3 and get URLs
    for (const file of selectedFiles) {
      try {
        const fileUrl = await uploadFileToS3(file);
        fileUrls.push({
          fileUrl,
          fileName: file.name,
          fileSize: file.size,
          uploadTime: new Date().toISOString(),
        });

        // Add notification for successful file upload
        await axios.post("https://api.aiflowpro.io/api/notification", {
          recipientId: localStorage.getItem("id"),
          senderId: "Skills App",
          title: "File Upload Successful",
          content: `File "${file.name}" has been uploaded successfully.`,
          urgency: "normal",
        });
      } catch (error) {
        console.error("Error uploading file", error);
        message.error("Failed to upload file: " + file.name);

        // Add notification for failed file upload
        await axios.post("https://api.aiflowpro.io/api/notification", {
          recipientId: localStorage.getItem("id"),
          senderId: "Skills App",
          title: "File Upload Failed",
          content: `Failed to upload file "${file.name}". Please try again.`,
          urgency: "high",
        });
      }
    }

    // Prepare data to send to the final endpoint
    const payload = {
      ...values,
      user: id,
      files: fileUrls, // Include file URLs with file data
    };

    try {
      // Submit form data along with uploaded file URLs
      const response = await axios.post(
        "https://api.aiflowpro.io/api/skill/",
        payload
      );
      console.log("response", response.data.data._id);
      // Send success notification for skill creation
      await axios.post("https://api.aiflowpro.io/api/notification", {
        recipientId: localStorage.getItem("id"),
        senderId: "Skills App",
        title: "Skill Created Successfully",
        content: `Your skill "${values.name}" has been created successfully.`,
        urgency: "normal",
      });

      // New API call for embeddings
      const uploadedUrls = response.data.data.files.map((file) => file.fileUrl);
      try {
        const res = await axios.post(
          "https://flowproai.dialect-ai.com/ai_flow_pro/create_skill_docs_embedding",
          {
            user_id: response.data.data.user,
            skill_id: response.data.data._id,
            uploaded_urls: uploadedUrls,
          }
        );

        // Success notification for embeddings
        await axios.post("https://api.aiflowpro.io/api/notification", {
          recipientId: localStorage.getItem("id"),
          senderId: "Skills App",
          title: "Embeddings Created Successfully",
          content: `Document embeddings for skill "${values.name}" have been created.`,
          urgency: "normal",
        });
      } catch (embeddingError) {
        console.error("Failed to create embeddings", embeddingError);

        // Add notification for failed embeddings creation
        await axios.post("https://api.aiflowpro.io/api/notification", {
          recipientId: localStorage.getItem("id"),
          senderId: "Skills App",
          title: "Embeddings Creation Failed",
          content: `Failed to create embeddings for skill "${values.name}". Please try again.`,
          urgency: "high",
        });
      }

      message.success("Skill created successfully!");
      navigate(`/run-skills/${response.data.data._id}`); // Navigate to run-skills with the new skill's ID
    } catch (error) {
      console.error("Failed to create skill", error);

      // Send failure notification
      await axios.post("https://api.aiflowpro.io/api/notification", {
        recipientId: localStorage.getItem("id"),
        senderId: "Skills App",
        title: "Skill Creation Failed",
        content: `Failed to create skill "${values.name}". Please try again.`,
        urgency: "high",
      });

      message.error("Failed to create skill");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container isOpen={isSidebarOpen}>
      <Header onClick={() => navigate("/skills")}>
        <img src={BackIcon} alt="back" />
        <Heading>Add new skill</Heading>
      </Header>
      <Content>
        <Form
          style={{ padding: "36px 36px" }}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontWeight: 600 }}>Name</span>}
                name="name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontWeight: 600 }}>Model</span>}
                name="model"
                rules={[{ required: true, message: "Please select a model" }]}
              >
                <Select placeholder="Select Model">
                  <Option value="gpt_3.5">Chat GPT 3.5</Option>
                  <Option value="gpt_4o">Chat GPT 4o</Option>
                  <Option value="gpt_4o_mini">Chat GPT 4o Mini</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontWeight: 600 }}>Objectives</span>}
                name="objectives"
                rules={[{ required: true, message: "Please enter objectives" }]}
              >
                <TextArea placeholder="Description" rows={4} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontWeight: 600 }}>Task Guidelines</span>}
                name="taskGuidelines"
              >
                <TextArea placeholder="Description" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontWeight: 600 }}>Required Output</span>}
                name="requiredOutput"
                rules={[
                  { required: true, message: "Please enter required output" },
                ]}
              >
                <TextArea placeholder="Description" rows={4} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontWeight: 600 }}>Other Rules and Steps</span>
                }
                name="otherRules"
              >
                <TextArea placeholder="Description" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          {/* Add new Query text area */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={<span style={{ fontWeight: 600 }}>Query</span>}
                name="query"
              >
                <TextArea placeholder="Enter your query" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={<span style={{ fontWeight: 600 }}>Upload Files</span>}
            name="files"
          >
            <Dragger
              name="files"
              multiple
              accept=".pdf"
              beforeUpload={() => false}
              onChange={handleFileChange}
              fileList={selectedFiles.map((file, index) => ({
                uid: index,
                name: file.name,
                status: "done",
                originFileObj: file,
              }))}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Drag and drop files, or <a>Browse</a>
              </p>
              <p className="ant-upload-hint">Support PDF files</p>
            </Dragger>
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Saving..." : "Save and test"}
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Container>
  );
}
