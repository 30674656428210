import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import {
  MaskingInput,
  MaskingInputLabel,
  MaskingInputList,
  MaskingInputListItem,
  MaskingInputListItemLabel,
  MaskingTypes,
  Footer,
  FooterButton,
} from "./Style";
import InfoIcon from "../../assets/info.svg";
import { MaskingInfo } from "../../Components/Constants/Constants";

const GeneralSettings = () => {
  const [masking, setMasking] = useState(false);
  const [maskingTypes, setMaskingTypes] = useState([
    {
      type: "CREDIT_CARD",
      description: "Recognizes credit card numbers (12-19 digits)",
      enabled: false,
    },
    {
      type: "CRYPTO",
      description: "Detects cryptocurrency wallet addresses (e.g., Bitcoin)",
      enabled: false,
    },
    {
      type: "DATE_TIME",
      description: "Identifies absolute or relative dates and times",
      enabled: false,
    },
    {
      type: "EMAIL_ADDRESS",
      description: "Recognizes email addresses",
      enabled: false,
    },
    {
      type: "IBAN_CODE",
      description: "Detects international bank account numbers",
      enabled: false,
    },
    {
      type: "IP_ADDRESS",
      description: "Identifies IPv4/IPv6 addresses",
      enabled: false,
    },
    {
      type: "NRP",
      description: "Nationality, religion, or political group identifiers",
      enabled: false,
    },
    {
      type: "LOCATION",
      description: "Identifies geographic or political locations",
      enabled: false,
    },
    {
      type: "PERSON",
      description: "Recognizes full personal names",
      enabled: false,
    },
    {
      type: "PHONE_NUMBER",
      description: "Recognizes telephone numbers",
      enabled: false,
    },
    {
      type: "MEDICAL_LICENSE",
      description: "Detects common medical license numbers",
      enabled: false,
    },
    { type: "URL", description: "Identifies web URLs", enabled: false },
    {
      type: "US_BANK_NUMBER",
      description: "Recognizes US bank account numbers",
      enabled: false,
    },
    {
      type: "US_DRIVER_LICENSE",
      description: "Detects US driver license numbers",
      enabled: false,
    },
    {
      type: "US_ITIN",
      description: "Recognizes US Individual Taxpayer Identification Numbers",
      enabled: false,
    },
    {
      type: "US_PASSPORT",
      description: "Detects US passport numbers",
      enabled: false,
    },
    {
      type: "US_SSN",
      description: "Identifies US Social Security Numbers",
      enabled: false,
    },
    {
      type: "UK_NHS",
      description: "Recognizes UK NHS numbers",
      enabled: false,
    },
    {
      type: "UK_NINO",
      description: "Recognizes UK National Insurance numbers",
      enabled: false,
    },
    {
      type: "ES_NIF",
      description: "Spanish NIF number (personal tax ID)",
      enabled: false,
    },
    {
      type: "ES_NIE",
      description: "Spanish NIE number (foreigners ID)",
      enabled: false,
    },
    {
      type: "IT_FISCAL_CODE",
      description: "Italian personal identification code",
      enabled: false,
    },
    {
      type: "IT_DRIVER_LICENSE",
      description: "Detects Italian driver license numbers",
      enabled: false,
    },
    {
      type: "IT_VAT_CODE",
      description: "Recognizes Italian VAT code numbers",
      enabled: false,
    },
    {
      type: "IT_PASSPORT",
      description: "Detects Italian passport numbers",
      enabled: false,
    },
    {
      type: "IT_IDENTITY_CARD",
      description: "Identifies Italian identity card numbers",
      enabled: false,
    },
    {
      type: "PL_PESEL",
      description: "Recognizes Polish PESEL numbers",
      enabled: false,
    },
    {
      type: "SG_NRIC_FIN",
      description: "Detects Singapore National Registration Identity Cards",
      enabled: false,
    },
    {
      type: "SG_UEN",
      description: "Recognizes Singapore Unique Entity Numbers",
      enabled: false,
    },
    {
      type: "AU_ABN",
      description: "Identifies Australian Business Numbers",
      enabled: false,
    },
    {
      type: "AU_ACN",
      description: "Recognizes Australian Company Numbers",
      enabled: false,
    },
    {
      type: "AU_TFN",
      description: "Australian Tax File Numbers",
      enabled: false,
    },
    {
      type: "AU_MEDICARE",
      description: "Recognizes Australian Medicare numbers",
      enabled: false,
    },
    // {
    //   type: "IN_PAN",
    //   description: "Indian Permanent Account Numbers",
    //   enabled: false,
    // },
    // {
    //   type: "IN_AADHAAR",
    //   description: "Indian Aadhaar ID numbers",
    //   enabled: false,
    // },
    // {
    //   type: "IN_VEHICLE_REGISTRATION",
    //   description: "Indian vehicle registration numbers",
    //   enabled: false,
    // },
    // {
    //   type: "IN_VOTER",
    //   description: "Indian voter ID numbers",
    //   enabled: false,
    // },
    // {
    //   type: "IN_PASSPORT",
    //   description: "Indian passport numbers",
    //   enabled: false,
    // },
    {
      type: "FI_PERSONAL_IDENTITY_CODE",
      description: "Finnish personal identity numbers",
      enabled: false,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const handleMaskingTypeChange = (index) => {
    if (masking) {
      const updatedMaskingTypes = [...maskingTypes];
      updatedMaskingTypes[index].enabled = !updatedMaskingTypes[index].enabled;
      setMaskingTypes(updatedMaskingTypes);
    }
  };

  const handleMaskingChange = () => {
    const newMaskingState = !masking;
    setMasking(newMaskingState);
    
    if (newMaskingState) {
      // When masking is turned on, enable all masking types
      const updatedMaskingTypes = maskingTypes.map(type => ({
        ...type,
        enabled: true
      }));
      setMaskingTypes(updatedMaskingTypes);
    }
  };

  useEffect(() => {
    handleGetMaskingTypes();
  }, []);
  const handleSaveMaskingTypes = async () => {
    try {
      setIsLoading(true);
      const userId = localStorage.getItem("id");
      const maskingData = maskingTypes.map((item) => ({
        key: item.type,
        value: item.enabled,
      }));
      const requestBody = {
        userId: userId,
        masking: masking,
        maskingData: maskingData,
      };
      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/editMasking",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error saving masking types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetMaskingTypes = async () => {
    const userId = localStorage.getItem("id");
    try {
      const response = await fetch(
        `https://api.aiflowpro.io/api/auth/getMasking?userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.responseCode === 200) {
        setMasking(data.masking);
        const updatedMaskingTypes = maskingTypes.map((type) => {
          const foundType = data.maskingData.find(
            (item) => item.key === type.type
          );
          return {
            ...type,
            enabled: foundType ? foundType.value : type.enabled,
            _id: foundType ? foundType._id : undefined,
          };
        });
        setMaskingTypes(updatedMaskingTypes);
      }
    } catch (error) {
      console.error("Error fetching masking types:", error);
    }
  };

  return (
    <>
      <MaskingInput>
        <MaskingInputLabel>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M5.12728 13.2053C3.92817 13.2053 2.90617 12.7828 2.06128 11.9379C1.21639 11.093 0.793945 10.071 0.793945 8.87192C0.793945 7.6727 1.21639 6.6507 2.06128 5.80592C2.90617 4.96103 3.92817 4.53859 5.12728 4.53859C6.3265 4.53859 7.3485 4.96103 8.19328 5.80592C9.03817 6.6507 9.46061 7.6727 9.46061 8.87192C9.46061 10.071 9.03817 11.093 8.19328 11.9379C7.3485 12.7828 6.3265 13.2053 5.12728 13.2053ZM5.12728 12.2053C6.0495 12.2053 6.83561 11.8803 7.48561 11.2303C8.13561 10.5803 8.46061 9.79414 8.46061 8.87192C8.46061 7.9497 8.13561 7.16359 7.48561 6.51359C6.83561 5.86359 6.0495 5.53859 5.12728 5.53859C4.20506 5.53859 3.41895 5.86359 2.76895 6.51359C2.11895 7.16359 1.79395 7.9497 1.79395 8.87192C1.79395 9.79414 2.11895 10.5803 2.76895 11.2303C3.41895 11.8803 4.20506 12.2053 5.12728 12.2053ZM10.2349 9.22309C10.2486 9.16931 10.2554 9.1137 10.2554 9.05625V8.87192C10.2554 7.45392 9.75528 6.2447 8.75494 5.24426C7.7545 4.24392 6.54528 3.74375 5.12728 3.74375H4.94295C4.8855 3.74375 4.82989 3.75059 4.77611 3.76425C5.03078 2.94881 5.52456 2.25009 6.25745 1.66809C6.99033 1.08598 7.8615 0.794922 8.87094 0.794922C10.0701 0.794922 11.0921 1.21737 11.9369 2.06225C12.7818 2.90714 13.2043 3.92914 13.2043 5.12825C13.2043 6.1377 12.9132 7.00887 12.3311 7.74175C11.7491 8.47464 11.0504 8.96842 10.2349 9.22309Z"
              fill="#22272F"
            />
          </svg>
          <span>Masking</span>
          <Tooltip title={MaskingInfo}>
            <img src={InfoIcon} alt="info" />
          </Tooltip>
          
        </MaskingInputLabel>
        <Switch checked={masking} onChange={handleMaskingChange} />
      </MaskingInput>
      {masking && (
        <MaskingTypes style={{ overflowY: "auto" }}>
          <MaskingInputList>
            {maskingTypes.map((item, index) => (
              <MaskingInputListItem key={index}>
                <Switch
                  checked={item.enabled && masking}
                  onChange={() => handleMaskingTypeChange(index)}
                  disabled={!masking}
                />
                <MaskingInputListItemLabel>
                  <span style={{ fontWeight: "600" }}>{item.type} :</span>
                  <span>{item.description}</span>
                </MaskingInputListItemLabel>
              </MaskingInputListItem>
            ))}
          </MaskingInputList>
        </MaskingTypes>
      )}
      <Footer>
        <FooterButton onClick={handleSaveMaskingTypes} disabled={isLoading}>
          {isLoading ? <CircularProgress size={16} /> : <span>Save</span>}
        </FooterButton>
      </Footer>
    </>
  );
};

export default GeneralSettings;