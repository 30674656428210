import React, { useState, useEffect } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import { useGoogleLogin } from "@react-oauth/google";
import { authGoogle, checkGoogleToken, GoogleCalendarUrl } from "./apiService";
import { CircularProgress } from "@mui/material";
import Check from "../../assets/Check.svg";

const SCOPES =
  "email profile openid https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";

const Calendar = ({ integration, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleAuthClick = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const requestBody = {
        email: localStorage.getItem("email"),
        code: tokenResponse.code,
      };
      const res = await authGoogle(requestBody);
      if (res.isSuccess) {
        console.log("Google Calendar res : ", res);
        setIsAuthorized(true);
        setIsLoading(false);
      } else {
        console.log("Google Calendar res : ", res);
        setIsAuthorized(false);
        setIsLoading(false);
      }
    },
    flow: "auth-code",
    redirectUri: GoogleCalendarUrl.REDIRECT_URI,
    scope: SCOPES,
    onNonOAuthError: () => {
      setIsLoading(false);
    },
  });

  const handleCheckGoogleToken = async () => {
    setIsLoading(true);
    const requestBody = {
      email: localStorage.getItem("email"),
    };
    try {
      const res = await checkGoogleToken(requestBody);
      console.log("check google token", res);
      if (res.isSuccess) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleCheckGoogleToken();
  }, []);

  return (
    <>
      <IntegrationCard key={index}>
        <AuthorizedTitle>
          <span
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <span>
              <img src={integration.icon} alt="icon" />
            </span>
            {integration.name}
          </span>
          {isAuthorized && <img src={Check} alt="check" />}
        </AuthorizedTitle>
        <SubText>{integration.description}</SubText>
        <AuthorizeButton
          onClick={handleAuthClick}
          disabled={isLoading || isAuthorized}
          isAuthorized={isAuthorized}
        >
          {isLoading ? (
            <CircularProgress size={13} color="inherit" />
          ) : isAuthorized ? (
            "Authorized"
          ) : (
            "Authorize"
          )}
        </AuthorizeButton>
      </IntegrationCard>
    </>
  );
};

export default Calendar;
