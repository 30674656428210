import React from 'react';
import { Popover, TextField, InputAdornment, Select, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { PopoverContent, SaveButton, PopoverHdr } from './Style';
import Database from '../../assets/Database.svg';
import { WORKFLOW_ACTIONS } from '../Constants/Constants';
// Base Popover component
const BasePopover = ({ anchorEl, onClose, children }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: '1rem',
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.08)',
          }
        }
      }}
    >
      <PopoverContent> {children} </PopoverContent>
    </Popover>
  );
};

// Specific Popover contents
const GetDataContent = ({ onSave, actionType = 'action' }) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  const handleSave = () => {
    // For profile type, set name as "Condition"
    const saveData = actionType === 'profile' 
      ? { name: 'Condition', description } 
      : { name, description };
    onSave(saveData);
  };

  return (
    <>
      <PopoverHdr style={{marginBottom: '1.44rem'}}>Action Info</PopoverHdr>
      {actionType !== 'profile' && (
        <Select
          IconComponent={null}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          displayEmpty
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '1rem',
            },
            '& .MuiSelect-select': {
              padding: "11px 16px",
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <img src={Database} alt="database" />
            </InputAdornment>
          }
        >
          <MenuItem value="" disabled>
            <em>Action Name</em>
          </MenuItem>
          {WORKFLOW_ACTIONS.map((action, index) => (
            <MenuItem key={index} value={action}>
              {action}
            </MenuItem>
          ))}
        </Select>
      )}
      <PopoverHdr style={{ 
        fontSize: "1rem", 
        fontWeight: "600", 
        marginTop: actionType === 'profile' ? "0" : "1.31rem", 
        marginBottom: "0.4rem"
      }}>
        {actionType === 'profile' ? 'Condition' : 'Action Description'}
      </PopoverHdr>
      <TextField
        placeholder={actionType === 'profile' ? "Enter Condition" : "Action Description"}
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '1rem',
          },
          "& .MuiOutlinedInput-input": {
            padding: "11px 16px",
          },
        }}
      />
      <SaveButton 
        variant="contained" 
        fullWidth 
        style={{marginTop: "2rem", borderRadius: "1rem"}} 
        onClick={handleSave}
      > 
        Save Info 
      </SaveButton>
    </>
  );
};

// Export different popover types
export const GetDataPopover = ({ anchorEl, onClose, onSave, actionType }) => (
  <BasePopover anchorEl={anchorEl} onClose={onClose}>
    <GetDataContent onSave={onSave} actionType={actionType} />
  </BasePopover>
);