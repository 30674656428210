import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  PageContainer,
  Title,
  Description,
  InputContainer,
  Input,
  NextButton,
  AvatarDiv,
  SelectContainer,
  Select,
} from "./style";
import { CircularProgress } from "@mui/material";

const AvatarInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [avatarName, setAvatarName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [greetingLanguage, setGreetingLanguage] = useState("english");

  const [existingAvatar, setExistingAvatar] = useState(null);

  const imageId = location.state?.imageId || ""; // Get imageId from state
  const userId = localStorage.getItem("id");

  useEffect(() => {
    const checkExistingAvatar = async () => {
      try {
        const response = await fetch(
          `https://api.aiflowpro.io/api/auth/get-avatar?user_id=${userId}`
        );
        
        const data = await response.json();
        
        if (data.responseCode === 200) {
          setExistingAvatar(data.avatar);
          setAvatarName(data.avatar.avatarName);
          setGreetingLanguage(data.avatar.greetingLanguage);
        } else if (data.responseCode === 404) {
          setExistingAvatar(null);
        }
      } catch (error) {
        console.error("Error checking existing avatar:", error);
      } finally {
        setLoading(false);
      }
    };
    console.log("existing avatar", existingAvatar);
    checkExistingAvatar();
  }, [userId]);

  const handleBackClick = () => {
    navigate("/avatar-selection");
  };

  const handleNextClick = async () => {
    setLoadingNext(true);

    try {
      const apiUrl = existingAvatar 
        ? "https://api.aiflowpro.io/api/auth/update-avatar"
        : "https://api.aiflowpro.io/api/auth/add-avatar";

      const payload = {
        user_id: userId,
        avatarName: avatarName.trim(),
        imageId: imageId,
        greetingLanguage: greetingLanguage
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // localStorage.setItem('avatarName', avatarName.trim());
        const isOnboarded = localStorage.getItem('isOnboarded') === 'true';
        navigate(isOnboarded ? "/avatar-chat" : "/");
      } else {
        console.error("Failed to add/update avatar.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingNext(false);
    }
  };

  // If still loading, show circular progress
  if (loading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <AvatarDiv>
        <Title>Enter Name</Title>
        <Description>
          Pick your personalized AI assistant—let's call it something awesome, like Nova—and watch it bring your ideas to life! 🚀
        </Description>
        <InputContainer>
          <Input
            type="text"
            placeholder="Enter Name"
            value={avatarName}
            onChange={(e) => setAvatarName(e.target.value)}
          />
        </InputContainer>
        <SelectContainer>
          <Select
            value={greetingLanguage}
            onChange={(e) => setGreetingLanguage(e.target.value)}
          >
            <option value="english">English</option>
            <option value="dutch">Dutch</option>
          </Select>
        </SelectContainer>
        <div style={{ display: "flex" }}>
          <NextButton
            style={{ background: "none", color: "#313133" }}
            onClick={handleBackClick}
          >
            Back
          </NextButton>
          <NextButton onClick={handleNextClick} disabled={loading}>
            {loadingNext ? <CircularProgress size={24} /> : "Next"}
          </NextButton>
        </div>
      </AvatarDiv>
    </PageContainer>
  );
};

export default AvatarInfo;