import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Header,
  Heading,
  ContentDiv,
  ProfileHeader,
  HeaderImg,
  EditButton,
  ProfileInfo,
  ProfileItem,
  ProfileLabel,
  ProfileValue,
  ModalOverlay,
  ModalContent,
  ModelInput,
  StyledSelect,
  ButtonDiv,
  CancelButton,
  SaveButton,
  NavHeader,
  NavItem,
  ProfileContainer,
} from "./Style";
import { useOutletContext, useNavigate } from "react-router-dom";
import BackIcon from "../../assets/back-icon.svg";
import CameraIcon from "../../assets/camera.svg";
import UserIcon from "../../assets/bw-user-icon.svg";
import EditIcon from "../../assets/edit.svg";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import PasswordModal from "../../Components/modals/PasswordModal";
import GeneralSettings from "./generalSettings";
export default function UpdateProfile() {
  const { isSidebarOpen, setProfileUpdated } = useOutletContext();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading
  const [snackbarOpen, setSnackbarOpen] = useState(false); // New state for snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for snackbar message
  const fileInputRef = useRef(null);
  const token = localStorage.getItem("token");

  const navItem = [
    {
      name: "Profile Settings",
    },
    {
      name: "General Settings",
    },
  ];
  const [activeNavItem, setActiveNavItem] = useState(navItem[0].name);

  useEffect(() => {
    setName(localStorage.getItem("name") || "");
    setGender(localStorage.getItem("gender") || "");
    setDob(localStorage.getItem("dob"));
    setEmail(localStorage.getItem("email") || "");
    const image = localStorage.getItem("image");
    setProfileImage(image || null);
  }, []);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
    setName(localStorage.getItem("name") || "");
    setGender(localStorage.getItem("gender") || "");
    setDob(localStorage.getItem("dob") || "");
    setEmail(localStorage.getItem("email") || "");
    setProfileImage(localStorage.getItem("image") || null);
    setSelectedFile(null);
  };

  const handleSave = async () => {
    setLoading(true); // Set loading to true when the API call starts
    try {
      let formattedDob = null;
      if (dob) {
        const parsedDob = new Date(dob);
        if (!isNaN(parsedDob)) {
          formattedDob = parsedDob.toISOString().split("T")[0];
        } else {
          throw new Error("Invalid date of birth format");
        }
      }

      const formData = new FormData();
      formData.append("name", name);
      if (formattedDob) {
        formData.append("dob", formattedDob);
      }
      formData.append("gender", gender);
      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/updateprofile",
        {
          method: "PUT",
          headers: {
            "x-auth-token": `${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        setSnackbarMessage(data.msg || "Network Error");
        setSnackbarOpen(true);
        return;
      }

      const data = await response.json();

      localStorage.setItem("name", data.user.name);
      localStorage.setItem("gender", data.user.gender);
      localStorage.setItem(
        "dob",
        new Date(data.user.dob).toISOString().split("T")[0]
      );
      localStorage.setItem("email", data.user.email);
      if (data.user.image) {
        localStorage.setItem("image", data.user.image);
        setProfileImage(data.user.image);
      }

      setSnackbarMessage("Profile Updated Successfully");
      setSnackbarOpen(true);
      setProfileUpdated(true);

      setTimeout(() => {
        setSnackbarOpen(false); // Close Snackbar after 1 second
        closeModal();
      }, 1000); // 1000 milliseconds = 1 second
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false); // Set loading to false when the API call is finished
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleShowPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const handleHidePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  return (
    <Container isOpen={isSidebarOpen}>
      <Header onClick={() => navigate("/")}>
        <img src={BackIcon} alt="back" />
        <Heading>Settings</Heading>
      </Header>
      <ContentDiv>
        <NavHeader>
          {navItem.map((item, index) => (
            <NavItem
              key={index}
              onClick={() => setActiveNavItem(item.name)}
              className={activeNavItem === item.name ? "active" : ""}
            >
              {item.name}
            </NavItem>
          ))}
        </NavHeader>
        {activeNavItem === "Profile Settings" && (
          <ProfileContainer>
            <ProfileHeader>
              <HeaderImg>
                <img
                  src={profileImage || UserIcon}
                  style={{
                    width: "3.75rem",
                    height: "3.75rem",
                    borderRadius: "50%",
                  }}
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = UserIcon;
                  }}
                />
              </HeaderImg>
              <EditButton onClick={openModal}>Edit</EditButton>
            </ProfileHeader>
            <ProfileInfo>
              <ProfileItem>
                <ProfileLabel>Full Name</ProfileLabel>
                <ProfileValue>{name}</ProfileValue>
              </ProfileItem>
              <ProfileItem>
                <ProfileLabel>Gender</ProfileLabel>
                <ProfileValue>{gender}</ProfileValue>
              </ProfileItem>
              <ProfileItem>
                <ProfileLabel>DOB</ProfileLabel>
                <ProfileValue>{dob}</ProfileValue>
              </ProfileItem>
              <ProfileItem>
                <ProfileLabel>Email</ProfileLabel>
                <ProfileValue>{email}</ProfileValue>
              </ProfileItem>
              <div style={{ display: "flex" }}>
                <ProfileItem>
                  <ProfileLabel>Password</ProfileLabel>
                  <ProfileValue>*********</ProfileValue>
                </ProfileItem>
                <img
                  src={EditIcon}
                  alt="edit"
                  onClick={handleShowPasswordModal}
                  style={{ marginBottom: "auto", cursor: "pointer" }}
                />
              </div>
            </ProfileInfo>
          </ProfileContainer>
        )}
        {activeNavItem === "General Settings" && <GeneralSettings />}
      </ContentDiv>
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <HeaderImg onClick={handleImageClick} style={{ cursor: "pointer" }}>
              <img
                src={profileImage || UserIcon}
                style={{
                  width: "5rem",
                  height: "5rem",
                  marginTop: "2rem",
                  borderRadius: "50%",
                }}
                alt="Profile"
                onError={(e) => {
                  e.target.src = UserIcon;
                }}
              />
              <img
                src={CameraIcon}
                style={{
                  position: "absolute",
                  width: "1.5rem",
                  height: "1.5rem",
                  marginTop: "5.5rem",
                  right: "0",
                }}
                alt="Camera Icon"
              />
            </HeaderImg>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
            <div style={{ display: "flex", gap: "1rem" }}>
              <ProfileInfo>
                <ProfileItem>
                  <ProfileLabel>Full Name</ProfileLabel>
                  <ModelInput
                    type="text"
                    value={name}
                    placeholder="John Doe"
                    onChange={(e) => setName(e.target.value)}
                  />
                </ProfileItem>
                <ProfileItem>
                  <ProfileLabel>Email</ProfileLabel>
                  <ModelInput
                    type="text"
                    value={email}
                    placeholder="John@gmail.com"
                    readOnly // Make email field uneditable
                  />
                </ProfileItem>
              </ProfileInfo>
              <ProfileInfo>
                <ProfileItem>
                  <ProfileLabel>DOB</ProfileLabel>
                  <ModelInput
                    type="date"
                    value={dob}
                    placeholder="24 July, 1990"
                    onChange={(e) => setDob(e.target.value)}
                    max={new Date().toISOString().split("T")[0]} // Prevent selecting future dates
                  />
                </ProfileItem>
                <ProfileItem>
                  <ProfileLabel>Gender</ProfileLabel>
                  <StyledSelect
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </StyledSelect>
                </ProfileItem>
              </ProfileInfo>
            </div>
            <ButtonDiv>
              <CancelButton onClick={closeModal}>Cancel</CancelButton>
              <SaveButton onClick={handleSave} disabled={loading}>
                {loading ? (
                  <CircularProgress size={21} color="inherit" />
                ) : (
                  "Save"
                )}
              </SaveButton>
            </ButtonDiv>
          </ModalContent>
        </ModalOverlay>
      )}
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={
          snackbarMessage === "Profile Updated Successfully"
            ? "success"
            : "error"
        }
      />
      {isPasswordModalOpen && (
        <PasswordModal onCancel={handleHidePasswordModal} />
      )}
    </Container>
  );
}
