import React, { useState, useEffect } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import { useGoogleLogin } from "@react-oauth/google";
import { CircularProgress } from "@mui/material";
import Check from "../../assets/Check.svg";

const SCOPES = "https://www.googleapis.com/auth/drive.file";

const Drive = ({ integration, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleAuthorize = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_INTEGRATIONS_REDIRECT_URI}&scope=https://www.googleapis.com/auth/drive.file&access_type=offline&prompt=consent`;
    window.location.href = authUrl;
  };

  const fetchTokens = async (authCode) => {
    setIsLoading(true);
    try {
      const response = await fetch("https://oauth2.googleapis.com/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          code: authCode,
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_INTEGRATIONS_REDIRECT_URI,
          grant_type: "authorization_code",
        }),
      });

      const data = await response.json();
      if (data.error) {
        console.error("Error fetching tokens:", data.error);
        return;
      }

      const { access_token, refresh_token, expires_in } = data;
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setExpiresIn(expires_in);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", expires_in);
      console.log("tokens", access_token, refresh_token, expires_in);
      setIsAuthorized(true);
    } catch (error) {
      console.error("Error fetching tokens:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("access_token");
    const storedRefreshToken = localStorage.getItem("refresh_token");
    const storedExpiresIn = localStorage.getItem("expires_in");

    if (storedAccessToken && storedRefreshToken) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
      setExpiresIn(storedExpiresIn);
      setIsAuthorized(true);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get("code");

    if (authCode && !accessToken) {
      fetchTokens(authCode);
    }
  }, [accessToken]);

  return (
    <IntegrationCard key={index}>
      <AuthorizedTitle>
        <span style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span>
            <img src={integration.icon} alt="icon" />
          </span>
          {integration.name}
        </span>
        {isAuthorized && <img src={Check} alt="check" />}
      </AuthorizedTitle>
      <SubText>{integration.description}</SubText>
      <AuthorizeButton
        onClick={handleAuthorize}
        disabled={isLoading || isAuthorized}
        isAuthorized={isAuthorized}
      >
        {isLoading ? (
          <CircularProgress size={13} color="inherit" />
        ) : isAuthorized ? (
          "Authorized"
        ) : (
          "Authorize"
        )}
      </AuthorizeButton>
    </IntegrationCard>
  );
};

export default Drive;