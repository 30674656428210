import styled, { keyframes } from "styled-components";
import { Box } from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  // min-height: 47.25rem;
  margin-right: 0rem;
  margin-left: ${({ isOpen }) => (isOpen ? "17.50rem" : "3.8rem")};
  @media (max-height: 850px) {
    margin-left: 0rem;
  }
`;

export const ContainerContent = styled.div`
  border-radius: 31px;
  border: 1px solid #fff;
  backdrop-filter: blur(40px);
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #fff;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const BackIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 31px;
  padding: 0.75rem 1rem;
  cursor: pointer;

  color: #3e473e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;

  transition: all 0.3s ease;
  &:hover {
    background-color: transparent;
    color: #fff;
  }
`;

export const PanZoomContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const PanZoomControls = styled.div`
  position: absolute;
  left: 12px;
  bottom: 12px;
`;

export const ButtonName = styled(Button)`
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 31px;
  padding: 0.75rem 1rem;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;

  transition: all 0.3s ease;
  &:hover {
    color: #000;
    border: 1px solid #000;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 100px;
  background: #fff;
  gap: 10px;
`;

export const FooterMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 100px;
  background: ${({ isActive }) => (isActive ? "#111224" : "transparent")};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #111224;
  }
`;

export const NodeContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  border-radius: 16px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);

  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  margin: 0px 8px;

  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  text-align: center;
`;

export const AddNodeContainer = styled.div`
  background: #1a74db;
  border-radius: 16px;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);

  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  margin: 0px 8px;
`;

export const HorizontalLine = styled.div`
  width: 100px;
  height: 3px;
  border-top: 3px dashed #fff;
`;

export const VerticalLine = styled.div`
  width: 3px;
  height: 100%;
  border-left: 3px dashed #fff;
`;

export const YesNoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const YesNoText = styled.p`
  margin-left: -30px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 0.5rem 1rem;
  color: var(--Dark-Blue-800, #3a3f52);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

// Popover Styling to be used in workflowBuilder/Popovers.jsx
export const PopoverContent = styled(Box)`
  padding: 1.25rem 1rem;
  width: 17.9375rem;
  background: #fff;
  border-radius: 1rem;
`;

export const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0.44rem;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 111.111% */
  border-radius: 1rem;
  background: #1a74db;
  cursor: pointer;
  &:hover {
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const PopoverHdr = styled(Box)`
  color: var(--Dark-Blue-800, #3a3f52);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
`;

export const PopoverMainHdr = styled(PopoverHdr)`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  img {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    filter: invert(1);
    transition: all 0.3s ease;
    &:hover {
      filter: invert(0);
    }
  }
`;
