import styled, {css} from "styled-components";

// export const HeroContainer = styled.div`
// `

export const MainDiv = styled.div`
  position: relative;
  width: max-content;

background: #FDFDFD;
  @media (min-height: 850px) and (width>786px){
    // isSidebarOpen={isSidebarOpen}
    padding-left:  ${({ isOpen }) => (isOpen ? '19rem' : '5.35rem')};
  }
  @media (max-height: 850px){
    margin-left: 2.6rem;
  }
  ${props => props.isOpen && css`
  @media (max-width: 786px) and (height> 850px) {
    margin-left: auto;
    margin-right: auto;
    }
  `}
`

export const PageHeading = styled.div`
color: #313133;
font-family: Inter;
font-size: 1.375rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.03rem;
margin-top: 2.31rem;
@media (max-width: 768px) {
  font-size: 1.125rem;
}
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  gap: 1rem;
  margin-top: 2.25rem;
  @media (max-width: 1320px) {
    grid-template-columns: repeat(2,0fr);
  }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(6, 0fr);
  }    
  ${props => props.isOpen && css`
    @media (max-width: 1260px) {
      grid-template-columns: repeat(2, 0fr);
    }
  `}
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  ${props => props.isOpen && css`
    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  `}

`;

export const LoadButton = styled.div`
width: 8.875rem;
display: flex;
align-items: center;
justify-content: center;
height: 2.4375rem;
border-radius: 0.3125rem;
background: #F1F1F5;
color: #696974;
text-align: center;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 2.94rem;
margin-bottom: 2.19rem;
cursor: pointer;
@media (max-width: 768px) {
  margin: 2.94rem auto 2.19rem auto; /* Centers the button horizontally */
}
  &:hover {
    opacity: 0.7; /* Reduces opacity to 70% on hover */
  }
`

export const BottomRightText = styled.div`
  position: absolute;
  bottom: -2rem;
  right: 2rem;
  color: #313133;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02625rem;
  @media (max-width: 768px) {
    display: none;
  }
`;