import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  GridContainer,
  MainDiv,
  LoadButton,
  BottomRightText,
  PageHeading,
} from "./Style";
import GridItems from "../GridItems/GridItems";
import EmailImg from "../../assets/mailing.svg";
import ChatIcon from "../../assets/chat.svg";
import SummaryIcon from "../../assets/summary.svg";
import RecordIcon from "../../assets/record.svg";
import SearchImg from "../../assets/search.svg";
import PlanIcon from "../../assets/Plan.svg";
import ForestIcon from "../../assets/forest.svg";
import TodoIcon from "../../assets/Todo.svg";
import Navbar from "../Navbar/Navbar";

const GridItemsObj = [
  {
    displayImg: EmailImg,
    headerTitle: "E-mail Automation",
    description:
      "Connect your email and start with AI automations to optimize your emails. Sort your emails into folders and setup auto replies.",
    btnTxt: "Use This",

  },
  {
    displayImg: ChatIcon,
    headerTitle: "Chat with Documents",
    description:
      "Upload you document en ask you AI assistant everything you want about the content of the document. ",
    btnTxt: "Use This",

  },
  {
    displayImg: SummaryIcon,
    headerTitle: "Summarize document",
    description:
      "Upload your document and let your AI assistant summarize the content for you. ",
    btnTxt: "Use This",

  },
  {
    displayImg: SearchImg,
    headerTitle: "General Search",
    description:
      "Use our search tool to find information on demand. No long strolls over the internet but a simple question and answer. ",
    btnTxt: "Use This",
    iframeSrc:
      "http://email-sorter-ssr-env.eba-jmuaduxk.eu-west-1.elasticbeanstalk.com/login/",
  },
  {
    displayImg: RecordIcon,
    headerTitle: "Record your meeting",
    description:
      "Record your meeting and let your AI assistant transcribe the whole meeting. Send the transcript or summary to all attandees.",
    btnTxt: "Start Using",

  },
  {
    displayImg: ForestIcon,
    headerTitle: "Forest App",
    description:
      "Promotes performance and personal wellness by taking breaks during work sessions. ",
    btnTxt: "Start Using",

  },
  {
    displayImg: TodoIcon,
    headerTitle: "Todo App",
    description:
      "Add, track, and prioritize tasks with urgency levels and due dates. Stay organized with filters, sorting options, and quick task updates.",
    btnTxt: "Start Using",
  },
  // {
  //   displayImg: PlanIcon,
  //   headerTitle: "Plan a Meeting",
  //   description:
  //     "Use our partnet MTNGS to set up meetings with multiple people without the hassle of finding a free slot in all calendars. ",
  //   btnTxt: "Start Using",

  // },
];
const HeroSection = () => {
   const navigate = useNavigate();
   const { isSidebarOpen, setIsSidebarOpen } = useOutletContext();


  const handleButtonClick = (index) => {
    switch (index) {
      case 0:
        navigate("/email");
        break;
      case 1:
        navigate("/chat");
        break;
      case 2:
        navigate("/summarize");
        break;
      case 3:
        navigate("/generalsearch");
        break;
      case 4:
        navigate("/transcriber");
        break;
      case 5:
        navigate("/forest");
        break;
      case 6:
        navigate("/todo");
        break;
      // Add more cases as needed for other navigation items
      default:
        break;
    } // Hide GridContainer when an item is clicked
  };

  return (
    <>
      <MainDiv isOpen={isSidebarOpen}>
        <Navbar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <PageHeading>Applications</PageHeading>
        <GridContainer isOpen={isSidebarOpen} style={{marginBottom: "3rem"}}>
          {GridItemsObj.map((item, index) => (
            <GridItems
              key={index}
              index={index}
              displayImg={item.displayImg}
              headerTitle={item.headerTitle}
              description={item.description}
              btnTxt={item.btnTxt}
              onButtonClick={() => handleButtonClick(index)}
            />
          ))}
        </GridContainer>
        {/* <LoadButton>Load More apps...</LoadButton> */}
        <BottomRightText>AI FLOW PRO</BottomRightText>
      </MainDiv>
    </>
  );
};
export default HeroSection;
