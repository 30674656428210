import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { ActionIcon, ProfileIcon } from "../assets/WorkflowIcons";
import { useState } from 'react';

const CustomDateTimePicker = ({ label, value, onChange, disablePast}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={onChange}
        disablePast={disablePast}
        sx={{
          backgroundColor: '#F1F1F5',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          borderRadius: '0.3125rem'
        }} 
      />
    </LocalizationProvider>
  );
};

const CustomSelect = ({ 
  value, 
  onChange, 
  options, 
  multiple = false,
  renderValue,
  size="small",
  disabled=false,
  placeholder="Select an option" // Add placeholder prop with default value
}) => {
  const selectStyles = {
    backgroundColor: '#F1F1F5',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    borderRadius: '0.3125rem',
    // Add styles for placeholder
    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
      WebkitTextFillColor: '#666666',
    }
  };

  return (
    <Select
      value={value || ''} // Use empty string when value is null/undefined
      onChange={onChange}
      multiple={multiple}
      renderValue={value ? renderValue : () => placeholder} // Show placeholder when no value
      sx={selectStyles}
      size={size}
      disabled={disabled}
      displayEmpty // Allow empty value to be displayed
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {multiple && <Checkbox checked={value.indexOf(option.value) > -1} />}
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  );
};

const getIconComponent = (type) => {
  switch (type) {
    case 'action':
      return ActionIcon;
    case 'profile':
      return ProfileIcon;
    default:
      return ActionIcon;
  }
};

const handleClearChat = async (userId) => {
  try {
    const response = await fetch("https://api.aiflowpro.io/api/auth/delete-chats", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
      }),
    });
                                       
    const data = await response.json();
    if (data.responseCode === 200) {
      console.error("Gen search chat cleared");
    } else {
      console.error("Failed to delete chats:", data.message);
    }
  } catch (error) {
    console.error("Failed to delete chats");
  } 
};

export { CustomDateTimePicker, CustomSelect, getIconComponent, handleClearChat };