import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  // min-height: 47.25rem;
  margin-right: 0rem;
  margin-left: ${({ isOpen }) => (isOpen ? "17.50rem" : "3.8rem")};
  @media (max-height: 850px) {
    margin-left: 0rem;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 31px;
  border: 1px solid #fff;
  backdrop-filter: blur(40px);
  padding: 1rem;
  height: calc(100% - 32px);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 31px;
  padding: 0.75rem 1rem;

  color: #3e473e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 31px;
  padding: 0.75rem 1rem;
  cursor: pointer;

  color: #3e473e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;

  transition: all 0.3s ease;
  &:hover {
    background-color: transparent;
    color: #fff;
  }
`;

export const WorkflowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  margin-top: 1rem;
  overflow: hidden;
`;

export const WorkflowCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  gap: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const WorkflowsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  gap: 0.25rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #202226;
`;

export const WorkflowName = styled.p`
  width: 25%;
  color: #3a3f52;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const WorkflowDescription = styled.p`
  width: 35%;
  color: #3a3f52;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const WorkflowStatus = styled.p`
  width: 15%;
  color: #3a3f52;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const WorkflowCreatedAt = styled.p`
  width: 15%;
  color: #3a3f52;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const WorkflowActions = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #36383c;
    transition: all 0.3s ease;
    &:hover {
      color: #202226;
    }
  }
`;
