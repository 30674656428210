import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import SendEmailModal from "./SendEmailModal";
import {
  Container,
  Form,
  LoginBox,
  Title,
  Input,
  InputLabel,
  FormText,
  SignInButton,
  ContentDiv,
  LogoBox,
} from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../assets/Logo.svg";
import VerificationModal from "./VerificationModal";

const Login = () => {
  const buttonRef = useRef(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [verificationModalIsOpen, setVerificationModalIsOpen] = useState(false); 

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user info is already stored in local storage
    const email = localStorage.getItem("email");
    if (email) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    //  "http://flowpro-be-env.eba-3sc2mhmy.eu-west-1.elasticbeanstalk.com/api/auth/login",

    try {
      const response = await fetch("https://api.aiflowpro.io/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.emailNotVerified) {
          setModalIsOpen(true);
        } else {
          setSnackbarMessage(data.msg || "Network Error");
          setSnackbarOpen(true);
          return
        }    
      }
      
      setSnackbarMessage("Login successful");
      setSnackbarOpen(true);

      setTimeout(() => {
        setSnackbarOpen(false);
        if (!data.user.isSubscribed) {
          const trialExpiryDate = localStorage.getItem("trialExpiryDate");
          if (!trialExpiryDate || new Date(trialExpiryDate) < new Date()) {
            navigate("/payment");
          } else {
            if (data.user.isOnboarded) {
              navigate("/");
            } else {
              navigate("/introduction");
            }
          }
        } else {
          if (data.user.isOnboarded) {
            navigate("/");
          } else {
            navigate("/introduction");
          }
        }
      }, 1000);

      localStorage.setItem("email", formData.email);
      localStorage.setItem("password", formData.password);
      // localStorage.setItem("password", formData.password);
      if (data.user.id) {
        localStorage.setItem("id", data.user.id);
      }
      if (data.user.name) {
        localStorage.setItem("name", data.user.name);
      }
      if (data.user.dob) {
        localStorage.setItem(
          "dob",
          new Date(data.user.dob).toISOString().split("T")[0]
        );
      }
      if (data.user.gender) {
        localStorage.setItem("gender", data.user.gender);
      }
      if (data.user.image) {
        localStorage.setItem("image", data.user.image);
      }
      if (data.user.stripeCustomerId) {
        localStorage.setItem("stripeCustomerId", data.user.stripeCustomerId);
      }
      if (data.user.isTodoOnboarded != null) {
        localStorage.setItem("isTodoOnboarded", data.user.isTodoOnboarded);
      }
      if (data.user.isForestOnboarded != null) {
        localStorage.setItem("isForestOnboarded", data.user.isForestOnboarded);
      }
      if (data.user.isSubscribed != null) {
        localStorage.setItem("isSubscribed", data.user.isSubscribed);
      }
      if (data.user.nextInvoice != null) {
        localStorage.setItem("nextInvoice", data.user.nextInvoice);
      }
      if (data.user.subsCancelsAt != null) {
        localStorage.setItem("subsCancelsAt", data.user.subsCancelsAt);
      }
      if (data.user.trialExpiryDate != null) {
        localStorage.setItem("trialExpiryDate", data.user.trialExpiryDate);
      }
      if (data.user.packageID != null) {
        localStorage.setItem("packageID", data.user.packageID);
      }
      if (data.user.cardBrand != null) {
        localStorage.setItem("cardBrand", data.user.cardBrand);
      }
      if (data.user.cardExpiryMonth != null) {
        localStorage.setItem("cardExpiryMonth", data.user.cardExpiryMonth);
      }
      if (data.user.cardExpiryYear != null) {
        localStorage.setItem("cardExpiryYear", data.user.cardExpiryYear);
      }
      if (data.user.isTrained != null) {
        localStorage.setItem("isTrained", data.user.isTrained);
      }
      if (data.user.cardLastFourDigits != null) {
        localStorage.setItem(
          "cardLastFourDigits",
          data.user.cardLastFourDigits
        );
      }
      if (data.token) {
        localStorage.setItem("token", data.token);
      }
      if (data.user.isOnboarded != null) {
        localStorage.setItem("isOnboarded", data.user.isOnboarded);
      }
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendEmail = async () => {
    try {
      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/resend-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to resend email");
      }

      const data = await response.json();
      setModalIsOpen(false); // Close the initial modal
      setVerificationModalIsOpen(true); // Open the VerificationModal
    } catch (error) {
      console.error("Error resending email:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container>
      <LoginBox>
        <ContentDiv>
          <LogoBox>
            <img src={Logo} alt="logo" />
          </LogoBox>
          <Title>Login</Title>
          <Form>
            <InputLabel>Email</InputLabel>
            <Input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
            <InputLabel style={{ marginTop: "1.31rem" }}>Password</InputLabel>
            <Input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
            />
            <FormText
              style={{
                marginLeft: "auto",
                marginTop: "0.69rem",
                cursor: "pointer",
              }}
              onClick={() => navigate('/forgotpassword')}
              onMouseOver={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              Forgot password?
            </FormText>
            <SignInButton
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
              ref={buttonRef}
            >
              {loading ? (
                <CircularProgress size={21} color="inherit" />
              ) : (
                "Sign In"
              )}
            </SignInButton>
          </Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormText>
              Don't have an account?{" "}
              <span
                style={{ color: "#0062FF", cursor: "pointer" }}
                onClick={() => navigate("/signup")}
                onMouseOver={(e) =>
                  (e.currentTarget.style.textDecoration = "underline")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.textDecoration = "none")
                }
              >
                Sign Up
              </span>
            </FormText>
          </div>
        </ContentDiv>
      </LoginBox>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarMessage === "Login successful" ? "success" : "error"}
      />
      <SendEmailModal
        modalIsOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        handleResendEmail={handleResendEmail} // Pass the handler to the modal
      />
      <VerificationModal
        modalIsOpen={verificationModalIsOpen}
        closeModal={() => setVerificationModalIsOpen(false)}
        email={formData.email}
      />
    </Container>
  );
};

export default Login;
