import React, { useState } from "react";
import ZapIconWhite from "../../assets/workflows/zap-white-icon.svg";
import ZapIconGrey from "../../assets/workflows/zap-grey-icon.svg";
import ConditionIconWhite from "../../assets/workflows/condition-white-icon.svg";
import ConditionIconGrey from "../../assets/workflows/condition-grey-icon.svg";
import EndIconGrey from "../../assets/workflows/end-grey-icon.svg";
import EndIconWhite from "../../assets/workflows/end-white-icon.svg";
import { Footer, FooterMenuItem } from "./style";
import Tooltip from "@mui/material/Tooltip";
import { Popover } from "@mui/material";

const TriggerPopovers = ({
  anchorElTrigger,
  onChooseTrigger,
  onCloseTrigger,
}) => {
  const [footerMenu, setFooterMenu] = useState([
    {
      name: "Action",
      isActive: true,
      icon: [ZapIconWhite, ZapIconGrey],
    },
    {
      name: "Condition",
      isActive: false,
      icon: [ConditionIconWhite, ConditionIconGrey],
    },
    {
      name: "End",
      isActive: false,
      icon: [EndIconWhite, EndIconGrey],
    },
  ]);

  const handleFooterMenuItemClick = (item) => {
    onChooseTrigger(item);
  };

  return (
    <Popover
      open={Boolean(anchorElTrigger)}
      anchorEl={anchorElTrigger}
      onClose={onCloseTrigger}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "1rem",
            boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.08)",
          },
        },
      }}
    >
      <Footer>
        {footerMenu.map((item, index) => (
          <div key={item.id}>
            <Tooltip title={item.name} placement="top">
              <FooterMenuItem
                isActive={item.isActive}
                onClick={() => handleFooterMenuItemClick(item)}
              >
                <img src={item.icon[item.isActive ? 0 : 1]} alt={item.name} />
              </FooterMenuItem>
            </Tooltip>
          </div>
        ))}
      </Footer>
    </Popover>
  );
};

export default TriggerPopovers;
