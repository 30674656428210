import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const email = localStorage.getItem("email");
  const isSubscribed = localStorage.getItem("isSubscribed");

  if (!email) {
    // User is not authenticated, redirect to login
    return <Navigate to="/login" />;
  }

  if (isSubscribed === "false") {
    const trialExpiryDate = localStorage.getItem("trialExpiryDate");
    if (!trialExpiryDate || new Date(trialExpiryDate) < new Date()) {
      return <Navigate to="/payment" />;
    }
  }

  // User is authenticated, allow access to the protected route
  return children;
};

export default ProtectedRoute;