import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IntroductionContainer,
  ContentWrapper,
  TextSection,
  Heading,
  IntroTxt,
  NextButton,
  ImageSection,
  LaptopImage,
  LogoContainer
} from './style';
import LaptopImg from "../../assets/introduction.jpg";
import LogoImg from "../../assets/Logo.svg";

const Introduction = () => {
  const navigate = useNavigate(); // Initialize navigate
  const handleNextClick = () => {
    navigate('/avatar-selection'); // Navigate to the /avatar-info page on click
  };

  return (
    <IntroductionContainer>
      <LogoContainer>
        <img src={LogoImg} alt="AI Flow Pro Logo" />
      </LogoContainer>
      <ContentWrapper>
        <TextSection>
          <Heading>Welcome to AI Flow Pro, your personal assistant!</Heading>
          <IntroTxt>
          Your ultimate personal assistant! Simplify your daily routine by setting up your assistant to handle repetitive tasks with ease. From sorting emails and tracking to-dos to actively participating in meetings and managing a wide range of tasks, AI Flow Pro has you covered. You can even create custom tasks tailored to your needs. Plus, with an integrated calendar, you’ll have complete control and organization at your fingertips.
          </IntroTxt>
          {/* <IntroTxt>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. 
            Velit for official consequat enim to main purpose. If you haven't tried out 
            Flaro App for them help.
          </IntroTxt> */}
          <NextButton onClick={handleNextClick} style={{marginTop: "3rem", width: "max-content"}}>Next</NextButton>
        </TextSection>
        <ImageSection>
          <img src={LaptopImg} alt="AI Flow PRO Dashboard" />
        </ImageSection>
      </ContentWrapper>
    </IntroductionContainer>
  );
};

export default Introduction;