import React from "react";
import { useOutletContext } from "react-router-dom";
import { Container, ContentContainer, Content, ChatBox, WorkFlowHdr, MainContnr, LeftSection, ActionButton, BottomActionBar, ExploreBtn} from "../Avatar/style";
import AvatarChatBg from "../../assets/avatar_chat_bg.png";
import ExploreWhite from "../../assets/explore-white.svg";
import { GetDataPopover } from './Popovers';
import { Grey, White, Black, Blue } from "../Constants/Constants";
import { ActionIcon, ProfileIcon, ActivityIcon, CalendarIcon } from "../../assets/WorkflowIcons";
import Line from "../../assets/line-dotted.svg";
import { getIconComponent } from "../../utils/utils";

const WorkflowBuilder = () => {
  const { isSidebarOpen } = useOutletContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAction, setSelectedAction] = React.useState('action');
  const [workflowSteps, setWorkflowSteps] = React.useState([]);

  // const handleContainerClick = (event) => {
  //   if (!event.target.closest('.bottom-action-bar')) {
  //     setSelectedAction(null);
  //   }
  // };

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBottomActionClick = (actionType, event) => {
    // event.stopPropagation();
    // console.log("event 2", event.currentTarget);
    // setAnchorEl(event.currentTarget);
    setSelectedAction(actionType);
  };

  const handleWorkflowSave = (workflowInfo) => {
    setWorkflowSteps([...workflowSteps, { ...workflowInfo, action: selectedAction }]);
    setAnchorEl(null);
  };

  const renderActionItem = (type, IconComponent) => {
    const isSelected = selectedAction === type;

    if (isSelected) {
      return (
        <ActionButton onClick={(e) => handleBottomActionClick(type, e)} style={{backgroundColor: Black, borderRadius: "50%", padding: "0.8rem"}}>
          <IconComponent fill={White} />
        </ActionButton>
      );
    }
    return (
      <ActionButton onClick={(e) => handleBottomActionClick(type, e)} style={{backgroundColor: White, borderRadius: "50%", padding: "0.8rem"}}>
        <IconComponent fill={Grey} />
      </ActionButton>
    );
  };

  return (
    <Container
      // onClick={handleContainerClick}
      isOpen={isSidebarOpen}
      style={{ marginRight: "0rem", backgroundImage: `url(${AvatarChatBg})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "100vh" }}
    >
      <ContentContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "2.5rem"}} >
          <Content style={{ height: "95vh" }}>
            <ChatBox>
              <WorkFlowHdr>Workflow builder</WorkFlowHdr>
              <MainContnr>
                <LeftSection>
                  <div style={{display: "flex", height: "100%", alignItems: "center"}}>
                    {workflowSteps.map((step, index) => (
                      <React.Fragment key={index}>
                        <ActionButton style={{background: White}}>
                          <ActionIcon fill={Black} />
                          <span>{step.name}</span>
                        </ActionButton>
                        <img src={Line} alt="connector" />
                      </React.Fragment>
                    ))}
                    <ActionButton onClick={handleActionClick} id="action-button" style={{background: Blue}}>
                      {React.createElement(getIconComponent(selectedAction), { fill: White })}
                    </ActionButton>
                  </div>
                    <BottomActionBar className="bottom-action-bar">
                      {renderActionItem('action', ActionIcon)}
                      {renderActionItem('profile', ProfileIcon)}
                      {renderActionItem('activity', ActivityIcon)}
                      {renderActionItem('calendar', CalendarIcon)}
                    </BottomActionBar>
                </LeftSection>
                {/* <ExploreBtn>
                  <img src={ExploreWhite} alt="explore" />
                  Explore
                </ExploreBtn> */}
              </MainContnr>
            </ChatBox>
          </Content>
        </div>  
      </ContentContainer>
      <GetDataPopover 
        anchorEl={anchorEl} 
        onClose={() => setAnchorEl(null)} 
        onSave={handleWorkflowSave}
        actionType={selectedAction}
      />
    </Container>
  );
};

export default WorkflowBuilder;
