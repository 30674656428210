import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import {
  SidebarContainer,
  Header,
  LogoIcon,
  Nav,
  NavItem,
  TeamStorage,
  Caption,
  ItemImg,
  ItemText,
  ToggleButton,
  DividerDiv,
  UserDiv,
  UserText,
  More,
  Coming,
  Popup,
  PopupItem,
  ExpandButton,
  Counter,
} from "./Style";
import { ComingSoon } from "../GridItems/Style";
import CircularProgress from "@mui/material/CircularProgress";
import GenSearchSideBar from "./GenSearchSideBar";
import { handleClearChat } from "../../utils/utils";
import Logo from "../../assets/Logo.svg";
import FolderIcon from "../../assets/folder.svg";
import SearchIcon from "../../assets/search.svg";
import MailingIcon from "../../assets/mailing.svg";
import ChatIcon from "../../assets/chat.svg";
import SummaryIcon from "../../assets/summary.svg";
import RecordIcon from "../../assets/record.svg";
import Divider from "../../assets/divider.svg";
import BellIcon from "../../assets/bell-icon.svg";
import SmallDivider from "../../assets/divider-small.svg";
import SettingIcon from "../../assets/settings.svg";
import ArrowLeft from "../../assets/arrow-left.svg";
import LogoImg from "../../assets/LogoImg.svg";
import UserIcon from "../../assets/user.png";
import DownArrorIcon from "../../assets/down-arrow.svg";
import ForestIcon from "../../assets/forest.svg";
import TodoIcon from "../../assets/Todo.svg";
import CalendarIcon from "../../assets/calendar.svg";
import AiIcon from "../../assets/ai-icon.svg";
import IntegrationsIcon from "../../assets/integrations.svg";
import ExpandIcon from "../../assets/more.svg";
import WorkflowIcon from "../../assets/workflow.svg";
import StorageIcon from "../../assets/Storage.svg";

const Sidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  profileUpdated,
  setProfileUpdated,
  notifUpdated,
  setNotifUpdated,
}) => {
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [userName, setUserName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isSearchSidebarVisible, setSearchSidebarVisible] = useState(false);
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const socketRef = useRef(null);
  const sidebarRef = useRef(null);

  const navItems = [
    { icon: AiIcon, text: "AI Assistant" },         // 0
    { icon: WorkflowIcon, text: "Workflows" },      // 1
    { icon: RecordIcon, text: "Skills" },           // 2
    { icon: CalendarIcon, text: "Assistant Calendar" }, // 3
    { icon: FolderIcon, text: "Applications" },     // 4
    { icon: MailingIcon, text: "E-mail automations" }, // 6
    { icon: ChatIcon, text: "Chat with doc" },      // 7
    { icon: SummaryIcon, text: "Summarize" },       // 8
    { icon: SearchIcon, text: "General search" },    // 9
    { icon: RecordIcon, text: "Meetings" },         // 10
    { icon: ForestIcon, text: "Forest App" },       // 11
    { icon: TodoIcon, text: "Todo App" },           // 12
    { icon: IntegrationsIcon, text: "Integrations" }, // 5
  ];

  const handleNavItemClick = (index) => {
    setActiveNavItem(index);
    const routes = {
      0: "/avatar-chat",
      1: "/workflows",
      2: "/skills",
      3: "/avatar-calendar",
      4: "/",
      5: "/email",
      6: "/chat",
      7: "/summarize",
      8: "/generalsearch",
      9: "/transcriber",
      10: "/forest",
      11: "/todo",
      12: "/integrations",
      13: "/storage"  // Keep storage route for the separate nav item
    };
    
    if (index === 13) {  // Special handling for Storage
      setSearchSidebarVisible(true);
      return;
    }
    
    if (routes[index]) {
      navigate(routes[index]);
    }
  };

  // Always visible items (in new order)
  const alwaysVisibleIndexes = [0, 1, 2, 3, 4, 12];
  
  // Items that should be shown when Applications is expanded
  const expandableIndexes = [5, 6, 7, 8, 9, 10, 11];

  // Set of indices that have active routes
  const navItemsWithRoutes = new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]);

  useEffect(() => {
    const userId = localStorage.getItem("id");
    if (!userId) return;

    socketRef.current = io("https://api.aiflowpro.io", {
      withCredentials: true,
      transports: ["websocket"],
    });

    socketRef.current.emit("join", userId);

    socketRef.current.on("newNotification", (data) => {
      setNotificationCount(data.count);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const image = localStorage.getItem("image");
    setProfileImage(image || null);
    const storedName = localStorage.getItem("name");
    if (storedName) {
      setUserName(storedName);
    }

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (profileUpdated) {
      setProfileImage(localStorage.getItem("image"));
      setUserName(localStorage.getItem("name"));
      setProfileUpdated(false);
    }
  }, [profileUpdated, setProfileUpdated]);

  useEffect(() => {
    if (notifUpdated) {
      fetchNotificationCount();
      setNotifUpdated(false);
    }
  }, [notifUpdated]);

  const fetchNotificationCount = async () => {
    try {
      const userId = localStorage.getItem("id");
      if (!userId) return;

      const response = await fetch(
        `https://api.aiflowpro.io/api/notification/unread-count?userId=${userId}`
      );
      const data = await response.json();

      if (data.responseCode === 200) {
        setNotificationCount(data.count);
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    setShowPopup(false);
    navigate("/login");
  };

  const handleNotificationClick = () => {
    setActiveNavItem(11);
    navigate("/notifications");
  };

  const handleUpdateProfile = () => {
    setShowPopup(false);
    navigate("/settings");
  };

  const handleBilling = () => {
    setShowPopup(false);
    navigate("/billing");
  };

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleGenSearchChatClear = () => {
    const userId = localStorage.getItem("id");
    handleClearChat(userId);
  };

  return (
    <>
      <SidebarContainer isOpen={isSidebarOpen}>
        <Header>
          <LogoIcon src={isSidebarOpen ? Logo : LogoImg} onClick={() => navigate("/avatar-chat")}/>
          {isSidebarOpen && <Caption>Start using your AI apps</Caption>}
        </Header>
        <Nav>
          {navItems.map((item, index) => (
            <NavItem
              isOpen={isSidebarOpen}
              key={index}
              isActive={activeNavItem === index}
              onClick={() => handleNavItemClick(index)}
              hasRoute={navItemsWithRoutes.has(index)}
              className={`nav-item-${index}`}
              style={{
                display:
                  alwaysVisibleIndexes.includes(index) ||
                  (isExpanded && expandableIndexes.includes(index))
                    ? "flex"
                    : "none",
              }}
            >
              <ItemImg src={item.icon} isOpen={isSidebarOpen} />
              {isSidebarOpen && <ItemText>{item.text}</ItemText>}
              {index === 4 && isSidebarOpen && (
                <ExpandButton onClick={toggleExpand} isExpanded={isExpanded}>
                  <img src={ExpandIcon} alt="expand" />
                </ExpandButton>
              )}
            </NavItem>
          ))}
        </Nav>
        <DividerDiv src={isSidebarOpen ? Divider : SmallDivider} alt="divider" />
        <Nav style={{overflowY: "hidden"}}>
          <NavItem
            isOpen={isSidebarOpen}
            isActive={activeNavItem === 13}
            onClick={() => handleNavItemClick(13)}
            hasRoute={true}
            className="nav-item-13"
          >
            <ItemImg src={StorageIcon} isOpen={isSidebarOpen} />
            {isSidebarOpen && <ItemText>Storage</ItemText>}
          </NavItem>
        </Nav>
        <div style={{ marginTop: "auto", display: "flex", flexDirection: "column", width: "70%" }}>
          <Nav style={{ width: "100%" }}>
            <NavItem
              isOpen={isSidebarOpen}
              isActive={activeNavItem === 11}
              onClick={handleNotificationClick}
              hasRoute={true}
            >
              <ItemImg src={BellIcon} isOpen={isSidebarOpen} />
              {isSidebarOpen && <ItemText>Notification</ItemText>}
              {notificationCount > 0 && (
                <Counter isOpen={isSidebarOpen}>{notificationCount}</Counter>
              )}
            </NavItem>
          </Nav>
          <DividerDiv src={isSidebarOpen ? Divider : SmallDivider} alt="divider" />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <UserDiv>
              <img
                src={profileImage || UserIcon}
                alt="avatar"
                style={{ borderRadius: "50%", width: "2rem", height: "2rem" }}
              />
              <UserText isOpen={isSidebarOpen}>{userName}</UserText>
              <More
                className="nav-item-settings"
                isOpen={isSidebarOpen}
                src={DownArrorIcon}
                alt="arrow"
                style={{ transform: "rotate(180deg)" }}
                onClick={() => setShowPopup(!showPopup)}
              />
              <Popup isOpen={showPopup} ref={popupRef}>
                <PopupItem onClick={handleUpdateProfile}>Settings</PopupItem>
                <PopupItem onClick={handleBilling}>Billing</PopupItem>
                <PopupItem onClick={handleLogout}>Logout</PopupItem>
              </Popup>
            </UserDiv>
          </div>
        </div>
        <ToggleButton onClick={toggleSidebar}>
          <img
            src={ArrowLeft}
            alt="toggle"
            style={{
              transform: isSidebarOpen ? "rotate(0deg)" : "rotate(180deg)",
              position: "absolute",
              zIndex: "10000",
              marginLeft: "-20px",
            }}
          />
        </ToggleButton>
      </SidebarContainer>
      
      {isSearchSidebarVisible && (
        <GenSearchSideBar
          ref={sidebarRef}
          onClose={() => setSearchSidebarVisible(false)}
          onUpdate={handleGenSearchChatClear}
        />
      )}
    </>
  );
};

export default Sidebar;