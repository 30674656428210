import React, { useState } from "react";
import { Container, Header, Heading, Content } from "../Summarize/Style";
import { useNavigate, useOutletContext } from "react-router-dom";
import BackIcon from "../../assets/back-icon.svg";
// import MenuItem from "@mui/material/MenuItem";
// import SearchIcon from "../../assets/searchicon.svg";
// import InputAdornment from "@mui/material/InputAdornment";
import {
  integrations,
  // StyledTextField,
  // StyledSelect,
} from "../Constants/Constants";
import {
  SearchContainer,
  IntegrationsGrid,
  IntegrationCard,
  CardTitle,
  AuthorizeButton,
  SubText,
} from "./style";
import Calendar from "./calendar";
import Drive from "./Drive";
import Email from "./Email";

const Integrations = () => {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  // const [sortBy, setSortBy] = useState("");

  return (
    <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
      <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }} onClick={() => navigate("/")}>
          <img src={BackIcon} alt="back" />
          <Heading>Integrations</Heading>
        </div>
      </Header>
      <Content
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          overflowY: "auto",
          padding: "1.25rem 1rem",
          gap: "1.25rem",
        }}
      >
        {/* <SearchContainer>
          <StyledTextField
            label="Search Integrations"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search" />
                </InputAdornment>
              ),
            }}
          />
          <StyledSelect
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            displayEmpty
            size="small"
            style={{ minWidth: 120 }}
          >
            <MenuItem value="">Sort by</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="recent">Recent</MenuItem>
          </StyledSelect>
        </SearchContainer> */}
        <IntegrationsGrid>
          {integrations.map((integration, index) => {
            if (integration.name === "Google Calendar") {
              return (
                <Calendar key={index} integration={integration} index={index} />
              );
            } else if (integration.name === "Google Drive") {
              return (
                <Drive key={index} integration={integration} index={index} />
              );
            } else if (integration.name === "Gmail") {
              return (
                <Email key={index} integration={integration} index={index} />
              );
            } else {
              return (
                <IntegrationCard key={index}>
                  <CardTitle>
                    <span>
                      <img src={integration.icon} alt="icon" />
                    </span>
                    {integration.name}
                  </CardTitle>
                  <SubText>{integration.description}</SubText>
                  <AuthorizeButton>Authorize</AuthorizeButton>
                </IntegrationCard>
              );
            }
          })}
        </IntegrationsGrid>
      </Content>
    </Container>
  );
};

export default Integrations;
