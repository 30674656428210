import React, { useState, useEffect } from "react";
import { Menu, Button, TextField, Box, Typography } from "@mui/material";
import { ButtonName } from "./style";

const WorkflowName = ({ workflowSettings, onSave }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (workflowSettings.name) {
      setName(workflowSettings.name);
    }
    if (workflowSettings.description) {
      setDescription(workflowSettings.description);
    }
  }, [workflowSettings.name, workflowSettings.description]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNameError(false);
    setDescriptionError(false);
  };

  const handleAdd = () => {
    if (!name || !description) {
      setNameError(!name);
      setDescriptionError(!description);
      return;
    }
    const workflowSettings = {
      name: name,
      description: description,
    };
    onSave(workflowSettings);
    handleClose();
  };

  return (
    <div>
      <ButtonName onClick={handleOpen}>
        {name ? name : "Add Workflow Name"}
      </ButtonName>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "set-workflow-name-button",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box sx={{ padding: 2, width: "300px" }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Title
          </Typography>
          <TextField
            placeholder="Add a Workflow Name"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(false);
            }}
            error={nameError}
            helperText={nameError ? "Workflow name is required" : ""}
            sx={{ marginBottom: 2 }}
          />
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Description
          </Typography>
          <TextField
            fullWidth
            placeholder="Add a Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError(false);
            }}
            error={descriptionError}
            helperText={descriptionError ? "Description is required" : ""}
            sx={{ marginBottom: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" onClick={handleAdd}>
              Save
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default WorkflowName;
