import React, { useState, useEffect } from 'react';
import { SkillsContainer, SkillCard, SkillHeader, SkillIcon, SkillName, RunButton, Tag, SearchContainer } from './style';
import play from "../../assets/play.svg";
import { WORKFLOW_ACTIONS } from "../Constants/Constants";
import { StyledTextField, StyledSelect, filterTags } from "../Constants/Constants";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "../../assets/searchicon.svg";
import InputAdornment from "@mui/material/InputAdornment";

const SkillsSection = ({ onRunSkill }) => {
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [userSkills, setUserSkills] = useState([]);

  const fetchWorkflows = async () => {
    try {
      const userId = localStorage.getItem('id');
      const response = await fetch(`https://api.aiflowpro.io/api/workflow/getworkflows?user_id=${userId}`);
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setWorkflows(data.workflows);
      }
    } catch (error) {
      console.error('Error fetching workflows:', error);
    }
  };

  const fetchUserSkills = async () => {
    try {
      const userId = localStorage.getItem('id');
      const response = await fetch(`https://api.aiflowpro.io/api/skill/user/${userId}`);
      const data = await response.json();
      
      if (data.success) {
        setUserSkills(data.skills);
      }
    } catch (error) {
      console.error('Error fetching user skills:', error);
    }
  };

  useEffect(() => {
    fetchWorkflows();
    fetchUserSkills();
  }, []);
  
  const handleRunSkill = (skillValue) => {
    if (onRunSkill) {
      onRunSkill(skillValue);
    }
  };

  const handleRunWorkflow = (workflowDescription) => {
    if (onRunSkill) {
      onRunSkill(`${workflowDescription}`);
    }
  };

  const handleRunUserSkill = (skillName) => {
    if (onRunSkill) {
      onRunSkill(`Run the skill ${skillName}`);
    }
  };

  const filteredWorkflowActions = WORKFLOW_ACTIONS.filter(item => {
    const nameMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || item.tag.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });

  const filteredWorkflows = workflows.filter(item => {
    const nameMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || 'workflow'.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });

  const filteredUserSkills = userSkills.filter(item => {
    const nameMatch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const tagMatch = !sortBy || 'skill'.toLowerCase() === sortBy.toLowerCase();
    return nameMatch && tagMatch;
  });
  
  return (
    <div style={{display: "flex", flexDirection: "column", gap: "1.25rem", width: "100%", padding: "1rem"}}>
      <SearchContainer>
        <StyledTextField
          placeholder="Search Skills"
          variant="outlined"
          fullWidth
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} alt="search" />
              </InputAdornment>
            ),
          }}
        />
        <StyledSelect
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          displayEmpty
          size="small"
          style={{ minWidth: 120 }}
        >
          {filterTags.map((tag) => (
            <MenuItem key={tag.value} value={tag.value}>
              {tag.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </SearchContainer>
      <SkillsContainer>
        {filteredWorkflowActions.map((skill, index) => (
          <SkillCard key={`skill-${index}`}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{skill.name}</SkillName>
              </SkillHeader>
              <Tag>{skill.tag}</Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {skill.description}
            </SkillHeader>
            <RunButton onClick={() => handleRunSkill(skill.value)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
        {filteredWorkflows.map((workflow) => (
          <SkillCard key={workflow._id}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{workflow.name}</SkillName>
              </SkillHeader>
              <Tag>workflow</Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {workflow.description}
            </SkillHeader>
            <RunButton onClick={() => handleRunWorkflow(workflow.description)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
        {filteredUserSkills.map((skill) => (
          <SkillCard key={skill._id}>
            <div style={{display: "flex", flexDirection: "column", gap: "0.62rem"}}>
              <SkillHeader>
                <SkillIcon>
                  <img src={play} alt="play" />
                </SkillIcon>
                <SkillName>{skill.name}</SkillName>
              </SkillHeader>
              <Tag>Skill</Tag>
            </div>
            <SkillHeader style={{fontSize: "0.875rem", fontWeight: "500"}}>
              {skill.objectives}
            </SkillHeader>
            <RunButton onClick={() => handleRunUserSkill(skill.name)}>
              Run
            </RunButton>
          </SkillCard>
        ))}
      </SkillsContainer>
    </div>
  );
};

export default SkillsSection;